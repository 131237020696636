import { PATH_VERSION3, PATH_SUB_DISTRIBUTOR } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAdminV3Layout from 'src/layouts/DashboardAdminV3Layout';
import DashboardSubDistributorLayout from '../layouts/DashboardSubDistributorLayout';



// AUTH PROTECT
import AuthProtectUser from 'src/components/Auth/AuthProtectUser/AuthProtectUser';

import AuthProtectAllOrder from 'src/components/Auth/AuthProtectAllOrder/AuthProtectAllOrder';

import AuthProtectOrderSales from 'src/components/Auth/AuthProtectOrderSales/AuthProtectOrderSales';
import AuthProtectOrderSalesView from 'src/components/Auth/AuthProtectOrderSales/AuthProtectOrderSalesView';
import AuthProtectOrderSalesEdit from 'src/components/Auth/AuthProtectOrderSales/AuthProtectOrderSalesEdit';
import AuthProtectOrderSalesAdd from 'src/components/Auth/AuthProtectOrderSales/AuthProtectOrderSalesAdd';

import AuthProtectOrderSalesManager from 'src/components/Auth/AuthProtectOrderSalesManager/AuthProtectOrderSalesManager';
import AuthProtectOrderSalesManagerView from 'src/components/Auth/AuthProtectOrderSalesManager/AuthProtectOrderSalesManagerView';
import AuthProtectOrderSalesManagerEdit from 'src/components/Auth/AuthProtectOrderSalesManager/AuthProtectOrderSalesManagerEdit';
import AuthProtectOrderSalesManagerAdd from 'src/components/Auth/AuthProtectOrderSalesManager/AuthProtectOrderSalesManagerAdd';

import AuthProtectOrderFinance from 'src/components/Auth/AuthProtectOrderFinance/AuthProtectOrderFinance';
import AuthProtectOrderFinanceView from 'src/components/Auth/AuthProtectOrderFinance/AuthProtectOrderFinanceView';
import AuthProtectOrderFinanceEdit from 'src/components/Auth/AuthProtectOrderFinance/AuthProtectOrderFinanceEdit';

import AuthProtectPaymentFinance from 'src/components/Auth/AuthProtectPaymentFinance/AuthProtectPaymentFinance';
import AuthProtectPaymentFinanceView from 'src/components/Auth/AuthProtectPaymentFinance/AuthProtectPaymentFinanceView';

import AuthProtectInvoiceFinance from 'src/components/Auth/AuthProtectInvoiceFinance/AuthProtectInvoiceFinance';
import AuthProtectInvoiceFinanceView from 'src/components/Auth/AuthProtectInvoiceFinance/AuthProtectInvoiceFinanceView';
import AuthProtectInvoiceFinanceEdit from 'src/components/Auth/AuthProtectInvoiceFinance/AuthProtectInvoiceFinanceEdit';

import AuthProtectInvoiceFinanceManager from 'src/components/Auth/AuthProtectInvoiceFinanceManager/AuthProtectInvoiceFinanceManager';
import AuthProtectInvoiceFinanceManagerView from 'src/components/Auth/AuthProtectInvoiceFinanceManager/AuthProtectInvoiceFinanceManagerView';
import AuthProtectInvoiceFinanceManagerEdit from 'src/components/Auth/AuthProtectInvoiceFinanceManager/AuthProtectInvoiceFinanceManagerEdit';

import AuthProtectInvoiceSales from 'src/components/Auth/AuthProtectInvoiceSales/AuthProtectInvoiceSales';
import AuthProtectInvoiceSalesView from 'src/components/Auth/AuthProtectInvoiceSales/AuthProtectInvoiceSalesView';

import AuthProtectTrackingSales from 'src/components/Auth/AuthProtectTrackingSales/AuthProtectTrackingSales';
import AuthProtectTrackingSalesView from 'src/components/Auth/AuthProtectTrackingSales/AuthProtectTrackingSalesView';

import AuthProtectTrackingLogistic from 'src/components/Auth/AuthProtectTrackingLogistic/AuthProtectTrackingLogistic';
import AuthProtectTrackingLogisticView from 'src/components/Auth/AuthProtectTrackingLogistic/AuthProtectTrackingLogisticView';
import AuthProtectTrackingLogisticEdit from 'src/components/Auth/AuthProtectTrackingLogistic/AuthProtectTrackingLogisticEdit';

import AuthProtectInboundLogistic from 'src/components/Auth/AuthProtectInboundLogistic/AuthProtectInboundLogistic';
import AuthProtectInboundLogisticAdd from 'src/components/Auth/AuthProtectInboundLogistic/AuthProtectInboundLogisticAdd';
import AuthProtectInboundLogisticEdit from 'src/components/Auth/AuthProtectInboundLogistic/AuthProtectInboundLogisticEdit';
import AuthProtectInboundLogisticView from 'src/components/Auth/AuthProtectInboundLogistic/AuthProtectInboundLogisticView';

import AuthProtectInboundSales from 'src/components/Auth/AuthProtectInboundSales/AuthProtectInboundSales';
import AuthProtectInboundSalesView from 'src/components/Auth/AuthProtectInboundSales/AuthProtectInboundSalesView';


import AuthProtectImportsFinance from 'src/components/Auth/AuthProtectImportsFinance/AuthProtectImportsFinance';
import AuthProtectImportsFinanceAdd from 'src/components/Auth/AuthProtectImportsFinance/AuthProtectImportsFinanceAdd';
import AuthProtectImportsFinanceView from 'src/components/Auth/AuthProtectImportsFinance/AuthProtectImportsFinanceView';
import AuthProtectImportsFinanceEdit from 'src/components/Auth/AuthProtectImportsFinance/AuthProtectImportsFinanceEdit';

import AuthProtectOfferTerms from 'src/components/Auth/AuthProtectOfferTerms/AuthProtectOfferTerms';
import AuthProtectOfferTermsAdd from 'src/components/Auth/AuthProtectOfferTerms/AuthProtectOfferTermsAdd';
import AuthProtectOfferTermsView from 'src/components/Auth/AuthProtectOfferTerms/AuthProtectOfferTermsView';
import AuthProtectOfferTermsEdit from 'src/components/Auth/AuthProtectOfferTerms/AuthProtectOfferTermsEdit';

import AuthProtectOfferSales from 'src/components/Auth/AuthProtectOfferSales/AuthProtectOfferSales';
import AuthProtectOfferSalesView from 'src/components/Auth/AuthProtectOfferSales/AuthProtectOfferSalesView';
import AuthProtectOfferSalesEdit from 'src/components/Auth/AuthProtectOfferSales/AuthProtectOfferSalesEdit';

import AuthProtectOfferSalesManager from 'src/components/Auth/AuthProtectOfferSalesManager/AuthProtectOfferSalesManager';
import AuthProtectOfferSalesManagerAdd from 'src/components/Auth/AuthProtectOfferSalesManager/AuthProtectOfferSalesManagerAdd';
import AuthProtectOfferSalesManagerEdit from 'src/components/Auth/AuthProtectOfferSalesManager/AuthProtectOfferSalesManagerEdit';
import AuthProtectOfferSalesManagerView from 'src/components/Auth/AuthProtectOfferSalesManager/AuthProtectOfferSalesManagerView';

import AuthProtectOfferAdmin from 'src/components/Auth/AuthProtectOfferAdmin/AuthProtectOfferAdmin';
import AuthProtectOfferAdminAdd from 'src/components/Auth/AuthProtectOfferAdmin/AuthProtectOfferAdminAdd';
import AuthProtectOfferAdminEdit from 'src/components/Auth/AuthProtectOfferAdmin/AuthProtectOfferAdminEdit';
import AuthProtectOfferAdminView from 'src/components/Auth/AuthProtectOfferAdmin/AuthProtectOfferAdminView';


import AuthProtectPreOrderSales from 'src/components/Auth/AuthProtectPreOrderSales/AuthProtectPreOrderSales';
import AuthProtectPreOrderSalesView from 'src/components/Auth/AuthProtectPreOrderSales/AuthProtetectPreOrderSalesView';
import AuthProtectPreOrderSalesEdit from 'src/components/Auth/AuthProtectPreOrderSales/AuthProtectPreOrderSalesEdit';
import AuthProtectPreOrderSalesAdd from 'src/components/Auth/AuthProtectPreOrderSales/AuthProtectPreOrderSalesAdd';

import AuthProtectPreOrderSalesManager from 'src/components/Auth/AuthProtectPreOrderSalesManager/AuthProtectPreOrderSalesManager';
import AuthProtectPreOrderSalesManagerView from 'src/components/Auth/AuthProtectPreOrderSalesManager/AuthProtectPreOrderSalesManagerView';
import AuthProtectPreOrderSalesManagerEdit from 'src/components/Auth/AuthProtectPreOrderSalesManager/AuthProtectPreOrderSalesManagerEdit';
import AuthProtectPreOrderSalesManagerAdd from 'src/components/Auth/AuthProtectPreOrderSalesManager/AuthProtectPreOrderSalesManagerAdd';

import AuthProtectClosedOffersBuyer from 'src/components/Auth/AuthProtectClosedOffersBuyer/AuthProtectClosedOffersBuyer';
import AuthProtectClosedOffersBuyerView from 'src/components/Auth/AuthProtectClosedOffersBuyer/AuthProtectClosedOffersBuyerView';

import AuthProtectClosedOffersBuyerManager from 'src/components/Auth/AuthProtectClosedOffersBuyerManager/AuthProtectClosedOffersBuyerManager';
import AuthProtectClosedOffersBuyerManagerView from 'src/components/Auth/AuthProtectClosedOffersBuyerManager/AuthProtectClosedOffersBuyerManagerView';


import AuthProtectPurchaseOrderBuyer from 'src/components/Auth/AuthProtectPurchaseOrderBuyer/AuthProtectPurchaseOrderBuyer';
import AuthProtectPurchaseOrderBuyerEdit from 'src/components/Auth/AuthProtectPurchaseOrderBuyer/AuthProtectPurchaseOrderBuyerEdit';
import AuthProtectPurchaseOrderBuyerView from 'src/components/Auth/AuthProtectPurchaseOrderBuyer/AuthProtectPurchaseOrderBuyerView';

import AuthProtectCosting from 'src/components/Auth/AuthProtectCosting/AuthProtectCosting';
import AuthProtectCostingAdd from 'src/components/Auth/AuthProtectCosting/AuthProtectCostingAdd';
import AuthProtectCostingEdit from 'src/components/Auth/AuthProtectCosting/AuthProtectCostingEdit';

import AuthProtectSKUBuyer from 'src/components/Auth/AuthProtectSKU/AuthProtectSKUBuyer';
import AuthProtectSKUBuyerView from 'src/components/Auth/AuthProtectSKU/AuthProtectSKUBuyerView';

import AuthProtectSupplier from 'src/components/Auth/AuthProtectSupplier/AuthProtectSupplier';
import AuthProtectSupplierViewEdit from 'src/components/Auth/AuthProtectSupplier/AuthProtectSupplierViewEdit';
import AuthProtectSupplierAdd from 'src/components/Auth/AuthProtectSupplier/AuthProtectSupplierAdd';

import AuthProtectRetailer from 'src/components/Auth/AuthProtectRetailer/AuthProtectRetailer';
import AuthProtectRetailerView from 'src/components/Auth/AuthProtectRetailer/AuthProtectRetailerView';
import AuthProtectRetailerAdd from 'src/components/Auth/AuthProtectRetailer/AuthProtectRetailerAdd';
import AuthProtectRetailerEdit from 'src/components/Auth/AuthProtectRetailer/AuthProtectRetailerEdit';

import AuthProtectReports from 'src/components/Auth/AuthProtectReports/AuthProtectReports';

import AuthProtectEmailSettings from 'src/components/Auth/AuthProtectEmailSettings/AuthProtectEmailSettings';


import AuthProtectAgreementTerms from 'src/components/Auth/AuthProtectAgreementTerms/AuthProtectAgreementTerms';
import AuthProtectAgreementTermsAdd from 'src/components/Auth/AuthProtectAgreementTerms/AuthProtectAgreementTermsAdd';
import AuthProtectAgreementTermsEdit from 'src/components/Auth/AuthProtectAgreementTerms/AuthProtectAgreementTermsEdit';
import AuthProtectAgreementTermsView from 'src/components/Auth/AuthProtectAgreementTerms/AuthProtectAgreementTermsView';

import AuthProtectPublicProfile from 'src/components/Auth/AuthProtectPublicProfile/AuthProtectPublicProfile';
import AuthProtectPublicProfileView from 'src/components/Auth/AuthProtectPublicProfile/AuthProtectPublicProfileView';
import AuthProtectPublicProfileAdd from 'src/components/Auth/AuthProtectPublicProfile/AuthProtectPublicProfileAdd';
import AuthProtectPublicProfileEdit from 'src/components/Auth/AuthProtectPublicProfile/AuthProtectPublicProfileEdit';

import AuthProtectRetailerGroup from 'src/components/Auth/AuthProtectRetailerGroup/AuthProtectRetailerGroup';
import AuthProtectRetailerGroupView from 'src/components/Auth/AuthProtectRetailerGroup/AuthProtectRetailerGroupView';
import AuthProtectRetailerGroupAdd from 'src/components/Auth/AuthProtectRetailerGroup/AuthProtectRetailerGroupAdd';
import AuthProtectRetailerGroupEdit from 'src/components/Auth/AuthProtectRetailerGroup/AuthProtectRetailerGroupEdit';

import AuthProtectOrderConsignment from 'src/components/Auth/AuthProtectOrderConsignment/AuthProtectOrderConsignment';
import AuthProtectOrderConsignmentAdd from 'src/components/Auth/AuthProtectOrderConsignment/AuthProtectOrderConsignmentAdd';
import AuthProtectOrderConsignmentView from 'src/components/Auth/AuthProtectOrderConsignment/AuthProtectOrderConsignmentView';
import AuthProtectOrderConsignmentEdit from 'src/components/Auth/AuthProtectOrderConsignment/AuthProtectOrderConsignmentEdit';


import AuthProtectConsignmentProducts from 'src/components/Auth/AuthProtectConsignmentProducts/AuthProtectConsignmentProducts';
import AuthProtectConsignmentProductsAdd from 'src/components/Auth/AuthProtectConsignmentProducts/AuthProtectConsignmentProductsAdd';
import AuthProtectConsignmentProductsView from 'src/components/Auth/AuthProtectConsignmentProducts/AuthProtectConsignmentProductsView';
import AuthProtectConsignmentProductsEdit from 'src/components/Auth/AuthProtectConsignmentProducts/AuthProtectConsignmntProductsEdit';

import AuthProtectConsignmentSKUOrder from 'src/components/Auth/AuthProtectConsignmentSKUOrder/AuthProtectConsignmentSKUOrder';
import AuthProtectConsignmentSKUOrderView from 'src/components/Auth/AuthProtectConsignmentSKUOrder/AuthProtectConsignmentSKUOrderView';
import AuthProtectConsignmentSKUOrderEdit from 'src/components/Auth/AuthProtectConsignmentSKUOrder/AuthProtectConsignmentSKUOrderEdit';

import AuthProtectConsignmentReport from 'src/components/Auth/AuthProtectConsignmentReport/AuthProtectConsignmentReport';
import AuthProtectConsignmentReportView from 'src/components/Auth/AuthProtectConsignmentReport/AuthProtectConsignmentReportView';

import AuthProtectConsignmentStockReturnable from 'src/components/Auth/AuthProtectConsignmentStockReturnable/AuthProtectConsignmentStockReturnable';
import AuthProtectConsignmentStockReturnableAdd from 'src/components/Auth/AuthProtectConsignmentStockReturnable/AuthProtectConsignmentStockReturnableAdd';
import AuthProtectConsignmentStockReturnableView from 'src/components/Auth/AuthProtectConsignmentStockReturnable/AuthProtectConsignmentStockReturnableView';
import AuthProtectConsignmentStockReturnableEdit from 'src/components/Auth/AuthProtectConsignmentStockReturnable/AuthProtectConsignmentStockReturnableEdit';

import AuthProtectRetailerStock from 'src/components/Auth/AuthProtectRetailerStock/AuthProtectRetailerStock';
import AuthProtectRetailerStockView from 'src/components/Auth/AuthProtectRetailerStock/AuthProtectRetailerStockView';

import AuthProtectRMA from 'src/components/Auth/AuthProtectRMA/AuthProtectRMA';
import AuthProtectRMAView from 'src/components/Auth/AuthProtectRMA/AuthProtectRMAView';

import AuthProtectCompanyAccount from 'src/components/Auth/AuthProtectCompanyAccount/AuthProtectCompanyAccount';
import AuthProtectCompanyAccountView from 'src/components/Auth/AuthProtectCompanyAccount/AuthProtectCompanyAccountView';

import AuthProtectSubDistributor from 'src/components/Auth/AuthProtectSubDistributor/AuthProtectSubDistributor';
import AuthProtectSubDistributorView from 'src/components/Auth/AuthProtectSubDistributor/AuthProtectSubDistributorView';

import AuthProtectSingleSidedRetailer from 'src/components/Auth/AuthProtectSingleSidedRetailer/AuthProtectSingleSidedRetailer';
import AuthProtectSingleSidedRetailerAdd from 'src/components/Auth/AuthProtectSingleSidedRetailer/AuthProtectSingleSidedRetailerAdd';
import AuthProtectSingleSidedRetailerView from 'src/components/Auth/AuthProtectSingleSidedRetailer/AuthProtectSingleSidedRetailerView';
import AuthProtectSingleSidedRetailerEdit from 'src/components/Auth/AuthProtectSingleSidedRetailer/AuthProtectSingleSidedRetailerEdit';

import AuthProtectOrderIssuesLogistic from 'src/components/Auth/AuthProtectOrderIssuesLogistic/AuthProtectOrderIssuesLogistic';
import AuthProtectOrderIssuesLogisticView from 'src/components/Auth/AuthProtectOrderIssuesLogistic/AuthProtectOrderIssuesLogisticView';
import AuthProtectOrderIssuesSales from 'src/components/Auth/AuthProtectOrderIssuesSales/AuthProtectOrderIssuesSales';
import AuthProtectOrderIssuesSalesView from 'src/components/Auth/AuthProtectOrderIssuesSales/AuthProtectOrderIssuesSalesView';
import AuthProtectOrderIssuesFinance from 'src/components/Auth/AuthProtectOrderIssuesFinance/AuthProtectOrderIssuesFinance';
import AuthProtectOrderIssuesFinanceView from 'src/components/Auth/AuthProtectOrderIssuesFinance/AuthProtectOrderIssuesFinanceView';



const AppAdminV3Routes = {
  path: PATH_VERSION3.root,
  guard: AuthProtect,
  layout: DashboardAdminV3Layout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_VERSION3.general.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/dashboard/DashboardAppView'))
    },

    // DASHBOARD RESTOCKS
    {
      exact: true,
      path: PATH_VERSION3.general.dashboardRestocks,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/dashboard/restocks/Restocks'))
    },

    // USER
    {
      exact: true,
      path: PATH_VERSION3.general.user,
      guard: AuthProtectUser,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/user-admin/User'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addUser,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/user-admin/AddUser'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.userDetails.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/user-admin/details/UserView'))
    },

    // NOTIFICATIONS
    {
      exact: true,
      path: PATH_VERSION3.general.notifications,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/notifications/Notifications'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.notificationDetail.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/notifications/detail/NotificationView'))
    },

    // PROFILE
    {
      exact: true,
      path: PATH_VERSION3.general.profile,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/profile/Profile'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.changePassword,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/profile/ChangePassword'))
    },

    // AGREEMENT TERMS
    {
      exact: true,
      path: PATH_VERSION3.general.agreementTerms,
      guard: AuthProtectAgreementTerms,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/agreement-terms/AgreementTerms'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addAgreementTerms,
      guard: AuthProtectAgreementTermsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/agreement-terms/NewAgreementComponent'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.agreementTermsDetails.edit,
      guard: AuthProtectAgreementTermsEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/agreement-terms/details/EditAgreementComponent'))
    },

    // PROFILE
    {
      exact: true,
      path: PATH_VERSION3.general.distributorProfile,
      guard: AuthProtectPublicProfile,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/distributor-profile/DistributorProfile'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.distributorProfileDetails.edit,
      guard: AuthProtectAgreementTermsEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/distributor-profile/edit/EditDistributorProfileComponent'))
    },

    // ORDER - ALL ORDER
    {
      exact: true,
      path: PATH_VERSION3.general.allOrder,
      guard: AuthProtectAllOrder,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/all-order/AllOrder'))
    },

    // ORDER - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.orderSales,
      guard: AuthProtectOrderSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderSales,
      guard: AuthProtectOrderSalesAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales/AddOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderSalesDetails.view,
      guard: AuthProtectOrderSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales/details/OrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderSalesDetails.edit,
      guard: AuthProtectOrderSalesEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales/details/OrderEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderSalesBulkUpload,
      guard: AuthProtectOrderSalesAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales/AddOrderBulkUpload'))
    },

    // ORDER - SALES MANAGER
    {
      exact: true,
      path: PATH_VERSION3.general.orderSalesManager,
      guard: AuthProtectOrderSalesManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales-manager/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderSalesManager,
      guard: AuthProtectOrderSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales-manager/AddOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderSalesManagerDetails.view,
      guard: AuthProtectOrderSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales-manager/details/OrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderSalesManagerDetails.edit,
      guard: AuthProtectOrderSalesManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales-manager/details/OrderEdit.js'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderSalesManagerBulkUpload,
      guard: AuthProtectOrderSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-sales-manager/AddOrderBulkUpload'))
    },

    // ORDER - CONSIGNMENT
    {
      exact: true,
      path: PATH_VERSION3.general.orderConsignment,
      guard: AuthProtectOrderConsignment,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-consignment/Order'))
    },  
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderConsignment,
      guard: AuthProtectOrderConsignmentAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-consignment/new/OrderConsignmentAdd.js'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderConsignmentBulkUpload,
      guard: AuthProtectOrderConsignmentAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-consignment/AddOrderConsignmentBulkUpload'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOrderConsignmentBulkUploadSingle,
      guard: AuthProtectOrderConsignmentAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-consignment/AddOrderConsignmentBulkUploadSingle'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderConsignmentDetails.view,
      guard: AuthProtectOrderConsignmentView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-consignment/details/OrderConsignmentView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderConsignmentDetails.edit,
      guard: AuthProtectOrderConsignmentEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-consignment/details/OrderConsignmentEdit'))
    },

    // ORDER - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.orderFinance,
      guard: AuthProtectOrderFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-finance/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderFinanceDetails.view,
      guard: AuthProtectOrderFinanceView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-finance/details/OrderEdit'))
    },

    // ORDER - LOGISTIC
    {
      exact: true,
      path: PATH_VERSION3.general.orderLogistic,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-logistic/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderLogisticDetails.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-logistic/details/OrderView'))
    },  
    {
      exact: true,
      path: PATH_VERSION3.general.orderLogisticDetails.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-logistic/details/OrderEdit'))
    },

    // ORDER - LOGISTIC MANAGER
    {
      exact: true,
      path: PATH_VERSION3.general.orderLogisticManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-logistic-manager/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderLogisticManagerDetails.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-logistic-manager/details/OrderView'))
    },

    // ORDER ISSUES - LOGISTIC
    {
      exact: true,
      path: PATH_VERSION3.general.orderIssuesLogistic,
      guard: AuthProtectOrderIssuesLogistic,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-issues-logistic/OrderIssues'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderIssuesLogisticDetails.view,
      guard: AuthProtectOrderIssuesLogisticView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-issues-logistic/detail/OrderIssuesView'))
    },

    // ORDER ISSUES - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.orderIssuesSales,
      guard: AuthProtectOrderIssuesSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-issues-sales/OrderIssues'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderIssuesSalesDetails.view,
      guard: AuthProtectOrderIssuesSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-issues-sales/detail/OrderIssuesView'))
    },

    // ORDER ISSUES - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.orderIssuesFinance,
      guard: AuthProtectOrderIssuesFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-issues-finance/OrderIssues'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderIssuesFinanceDetails.view,
      guard: AuthProtectOrderIssuesFinanceView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-issues-finance/detail/OrderIssuesView'))
    },

    // PAYMENT - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.paymentFinance,
      guard: AuthProtectPaymentFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/payment-finance/Payment'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.paymentFinanceDetails.view,
      guard: AuthProtectPaymentFinanceView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/payment-finance/details/PaymentView'))
    },

    // CREDIT NOTE
    {
      exact: true,
      path: PATH_VERSION3.general.creditNote,
      guard: AuthProtectPaymentFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/credit-note/CreditNote'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.creditNoteDetails.view,
      guard: AuthProtectPaymentFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/credit-note/details/CreditNoteView'))
    }, 

    // INVOICE - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinance,
      guard: AuthProtectInvoiceFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance/Invoice'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceDetails.view,
      guard: AuthProtectInvoiceFinanceView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance/details/InvoiceView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceDetails.edit,
      guard: AuthProtectInvoiceFinanceEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance/details/InvoiceEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceBulkUpload,
      guard: AuthProtectInvoiceFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance/InvoiceBulkUpload'))
    },

    // INVOICE - FINANCE MANAGER
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceManager,
      guard: AuthProtectInvoiceFinanceManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance-manager/Invoice'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceManagerDetails.view,
      guard: AuthProtectInvoiceFinanceManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance-manager/details/InvoiceView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceManagerDetails.edit,
      guard: AuthProtectInvoiceFinanceManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance-manager/details/InvoiceEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceFinanceManagerBulkUpload,
      guard: AuthProtectInvoiceFinanceManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-finance-manager/InvoiceBulkUpload'))
    },

    // INVOICE - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceSales,
      guard: AuthProtectInvoiceSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-sales/Invoice'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceSalesDetails.view,
      guard: AuthProtectInvoiceSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoices-sales/InvoiceView'))
    },

    // TRACKING - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.trackingSales,
      guard: AuthProtectTrackingSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/tracking-sales/Tracking'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.trackingSalesDetails.view,
      guard: AuthProtectTrackingSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/tracking-sales/TrackingView'))
    },

    // TRACK PAYMENT
    {
      exact: true,
      path: PATH_VERSION3.general.trackPayment,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/track-payment/TrackPayment'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.trackPaymentView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/track-payment/TrackPaymentView'))
    },

    // TRACKING - LOGISTIC
    {
      exact: true,
      path: PATH_VERSION3.general.trackingLogistic,
      guard: AuthProtectTrackingLogistic,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/tracking-logistic/Tracking'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.trackingLogisticDetails.view,
      guard: AuthProtectTrackingLogisticView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/tracking-logistic/TrackingView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.trackingLogisticDetails.edit,
      guard: AuthProtectTrackingLogisticEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/tracking-logistic/TrackingEdit'))
    },

    // INBOUND - LOGISTIC
    {
      exact: true,
      path: PATH_VERSION3.general.inboundLogistic,
      guard: AuthProtectInboundLogistic,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-logistic/Inbound'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addInboundLogistic,
      guard: AuthProtectInboundLogisticAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-logistic/AddInbound'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundLogisticDetails.view,
      guard: AuthProtectInboundLogisticView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-logistic/details/InboundView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundLogisticDetails.edit,
      guard: AuthProtectInboundLogisticEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-logistic/details/InboundEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundLogisticDetails.editGeneralInfo,
      guard: AuthProtectInboundLogisticEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-logistic/details/InboundEditGeneralInfo'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundLogisticDetails.editFiles,
      guard: AuthProtectInboundLogisticEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-logistic/details/InboundEditFiles'))
    },

    // INBOUND - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.inboundSales,
      guard: AuthProtectInboundSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-sales/Inbound'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundSalesView,
      guard: AuthProtectInboundSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-sales/InboundView'))
    },

    // IMPORT - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.inboundFinance,
      guard: AuthProtectImportsFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/import-finance/Import'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundFinanceDetails.view,
      guard: AuthProtectImportsFinanceView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/import-finance/ImportView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundFinanceDetails.edit,
      guard: AuthProtectImportsFinanceEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/import-finance/ImportEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundFinanceDetails.new,
      guard: AuthProtectImportsFinanceView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/import-finance/ImportNew'))
    },

    // PRODUCT 
    {
      exact: true,
      path: PATH_VERSION3.general.product,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addProduct,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/add-new/AddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productDetails.view,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/view/ViewProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productDetails.edit,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/edit/EditProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productBulkUpload.root,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/bulk-upload/BulkUpload'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productBulkUpload.addProductBulkUpload,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/bulk-upload/BulkUploadAddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productBulkUpload.editProductBulkUpload,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/bulk-upload/BulkUpdateEditProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productImagesBulkUpload.root,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/images-bulk-upload/ImagesBulkUpload'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productImagesBulkUpload.identicalImages,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/images-bulk-upload/ImagesBulkUploadIdentical'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productImagesBulkUpload.differentImages,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/images-bulk-upload/ImagesBulkUploadDifferent'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productCatalogue.root,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/product-catalogue/GenerateProductCatalogue'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productCatalogue.chooseProduct,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/product-catalogue/ChooseProductList'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productDetails.skuOrder,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product/view/SKUOrder'))
    },

    // OFFER TERMS
    {
      exact: true,
      path: PATH_VERSION3.general.offerTerms,
      guard: AuthProtectOfferTerms,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-terms/OfferTerms'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferTerms,
      guard: AuthProtectOfferTermsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-terms/add-new/AddOfferTerms'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerTermsDetail.view,
      guard: AuthProtectOfferTermsView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-terms/details/OfferTermsView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerTermsDetail.edit,
      guard: AuthProtectOfferTermsEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-terms/details/OfferTermsEdit'))
    },

    // PAYMENT TERMS
    {
      exact: true,
      path: PATH_VERSION3.general.paymentTerms,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/payment-terms/PaymentTerms'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addPaymentTerms,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/payment-terms/AddPaymentTerms'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.paymentTermsDetail.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/payment-terms/details/PaymentTermsView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.paymentTermsDetail.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/payment-terms/details/PaymentTermsEdit'))
    },

    // OFFER - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.offerSales,
      guard: AuthProtectOfferSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales/Offer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales/add-offers/AddOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesDetails.view,
      guard: AuthProtectOfferSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOffersSalesDetail.addProduct,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales/add-products/AddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesDetails.edit,
      guard: AuthProtectOfferSalesEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales/details/OfferEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesDetails.editProduct,
      guard: AuthProtectOfferSalesEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales/edit-products/EditProduct'))
    },

    // OFFER - SALES MANAGER
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManager,
      guard: AuthProtectOfferSalesManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager/Offer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferSalesManager,
      guard: AuthProtectOfferSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager/add-offers/AddOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerDetails.view,
      guard: AuthProtectOfferSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferSalesManagerDetail.addProduct,
      guard: AuthProtectOfferSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager/add-products/AddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerDetails.edit,
      guard: AuthProtectOfferSalesManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager/details/OfferEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerDetails.editProduct,
      guard: AuthProtectOfferSalesManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager/edit-products/EditProduct'))
    },

    // OFFER - SALES MANAGER - V2
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2,
      guard: AuthProtectOfferSalesManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/Offer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferSalesManagerV2,
      guard: AuthProtectOfferSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/add-offers/AddOffer'))
    },

    {
      exact: true,
      path: PATH_VERSION3.general.addOfferSalesManagerV2Sub.addProduct,
      guard: AuthProtectOfferSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/add-offers/add-product/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2Details.view,
      guard: AuthProtectOfferSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/details/ViewOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2Details.edit,
      guard: AuthProtectOfferSalesManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/details/EditOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2Details.manageRating,
      guard: AuthProtectOfferSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/details/rating/ManageRating'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2Details.manageRecommendedCartonQty,
      guard: AuthProtectOfferSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/details/recommended-carton-qty/ManageRecommendedCartonQty'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2Details.viewMode2,
      guard: AuthProtectOfferSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/details/ViewOfferMode2'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesManagerV2Details.viewRetailerList,
      guard: AuthProtectOfferSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-manager-v2/details/ViewRetailerList'))
    },

    // OFFER - ADMIN
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdmin,
      guard: AuthProtectOfferAdmin,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/Offer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferAdmin,
      guard: AuthProtectOfferAdminAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/add-offers/AddOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addOfferAdminSub.addProduct,
      guard: AuthProtectOfferAdminAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/add-offers/add-product/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdminDetails.view,
      guard: AuthProtectOfferAdminView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/details/ViewOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdminDetails.edit,
      guard: AuthProtectOfferAdminEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/details/EditOffer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdminDetails.manageRating,
      guard: AuthProtectOfferAdminView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/details/rating/ManageRating'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdminDetails.manageRecommendedCartonQty,
      guard: AuthProtectOfferAdminView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/details/recommended-carton-qty/ManageRecommendedCartonQty'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdminDetails.viewMode2,
      guard: AuthProtectOfferAdminView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/details/ViewOfferMode2'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerAdminDetails.viewRetailerList,
      guard: AuthProtectOfferAdminView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-admin/details/ViewRetailerList'))
    },

    // OFFER - SUPER ADMIN // DEPRECATED
    {
      exact: true,
      path: PATH_VERSION3.general.offerSuperAdmin,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offers-superadmin/Offer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSuperAdminDetails.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offers-superadmin/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSuperAdminDetails.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offers-superadmin/details/OfferEdit'))
    },

    // PRE ORDER - SALES UPDATED TO V2 PER 06022024
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSales,
      guard: AuthProtectPreOrderSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-v2/PreOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addPreOrderSales,
      guard: AuthProtectPreOrderSalesAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-v2/AddPreOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesDetails.view,
      guard: AuthProtectPreOrderSalesView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-v2/PreOrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesDetails.edit,
      guard: AuthProtectPreOrderSalesEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-v2/PreOrderEdit'))
    },

    // PRE ORDER - SALES MANAGER UPDATED TO V2 PER 07022024
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesManager,
      guard: AuthProtectPreOrderSalesManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-manager-v2/PreOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesManagerDetails.preOrderListDetail,
      guard: AuthProtectPreOrderSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-manager-v2/details/PreOrderListDetail'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesManagerDetails.viewPreOrder,
      guard: AuthProtectPreOrderSalesManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-manager-v2/details/PreOrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesManagerDetails.editPreOrder,
      guard: AuthProtectPreOrderSalesManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-manager-v2/details/PreOrderEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesManagerDetails.addPreOrder,
      guard: AuthProtectPreOrderSalesManagerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-manager-v2/details/AddPreOrder'))
    },

    /**,
   ,
    {
      exact: true,
      path: PATH_VERSION3.general.preOrderSalesManagerDetails.edit,
      guard: AuthProtectPreOrderSalesManagerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/preorder-sales-manager/PreOrderEdit'))
    },*/
    
    // CLOSED OFFERS - BUYER
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyer,
      guard: AuthProtectClosedOffersBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerDetails.view,
      guard: AuthProtectClosedOffersBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer/ClosedOffersView'))
    },

    // CLOSED OFFERS - BUYER - V2 ROS
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerV2,
      guard: AuthProtectClosedOffersBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-v2/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerV2Details.new,
      guard: AuthProtectClosedOffersBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-v2/details/ClosedOffersNew'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerV2Details.view,
      guard: AuthProtectClosedOffersBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-v2/details/ClosedOffersView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerV2Details.viewPOBreakdown,
      guard: AuthProtectClosedOffersBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-v2/details/ClosedOffersPOBreakdown'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerV2Details.edit,
      guard: AuthProtectClosedOffersBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-v2/details/ClosedOffersEdit'))
    },

    // CLOSED OFFERS - BUYER MANAGER - V2 ROS
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2,
      guard: AuthProtectClosedOffersBuyerManager,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.new,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/ClosedOffersNew'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.view,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/ClosedOffersView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.edit,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/ClosedOffersEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.viewPOBreakdown,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/ClosedOffersPOBreakdown'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.preOrderCompilation,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/PreOrderCompilation'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.manageRating,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/rating/ManageRating'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.manageRecommendedCartonQty,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/details/recommended-carton-qty/ManageRecommendedCartonQty'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.closedOffersBuyerManagerV2Details.viewRetailerList,
      guard: AuthProtectClosedOffersBuyerManagerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/closed-offers-buyer-manager-v2/ViewRetailerList'))
    },

    // PURCHASE ORDER - BUYER
    /** {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyer,
      guard: AuthProtectPurchaseOrderBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer/PurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addPurchaseOrderBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer/AddPurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyerDetails.view,
      guard: AuthProtectPurchaseOrderBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer/PurchaseOrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyerDetails.edit,
      guard: AuthProtectPurchaseOrderBuyerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer/PurchaseOrderEdit'))
    }, */

    // PURCHASE ORDER - BUYER - VERSION 2
    {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyerV2,
      guard: AuthProtectPurchaseOrderBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer-v2/PurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyerV2Details.view,
      guard: AuthProtectPurchaseOrderBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer-v2/details/PurchaseOrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyerV2Details.edit,
      guard: AuthProtectPurchaseOrderBuyerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer-v2/details/PurchaseOrderEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.purchaseOrderBuyerV2Details.viewPOBreakdown,
      guard: AuthProtectPurchaseOrderBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/purchase-order-buyer-v2/details/PurchaseOrderPOBreakdown'))
    },

    // PRICING
    {
      exact: true,
      path: PATH_VERSION3.general.pricing,
      guard: AuthProtectCosting,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/Pricing'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addNewPricing.option1Mode,
      guard: AuthProtectCostingAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/add-new/Option1Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addNewPricing.option2Mode,
      guard: AuthProtectCostingAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/add-new/Option2Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addNewPricing.option3Mode,
      guard: AuthProtectCostingAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/add-new/Option3Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.viewPricing.option1Mode,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/view/Option1Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.viewPricing.option2Mode,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/view/Option2Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.viewPricing.option3Mode,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/view/Option3Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.editPricing.option1Mode,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/edit/Option1Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.editPricing.option2Mode,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/edit/Option2Mode'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.editPricing.option3Mode,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pricing/edit/Option3Mode'))
    },

    // COSTING
    {
      exact: true,
      path: PATH_VERSION3.general.costingView,
      guard: AuthProtectCosting,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/costing/CostingView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.costingEdit,
      guard: AuthProtectCostingEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/costing/CostingEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.costingNew,
      guard: AuthProtectCostingAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/costing/CostingNew'))
    },

    // SKU - BUYER
    {
      exact: true,
      path: PATH_VERSION3.general.skuBuyer,
      guard: AuthProtectSKUBuyer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/sku-buyer/SKU'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.skuBuyerDetails.view,
      guard: AuthProtectSKUBuyerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/sku-buyer/details/SKUView'))
    },

    // SUPPLIER
    {
      exact: true,
      path: PATH_VERSION3.general.supplier,
      guard: AuthProtectSupplier,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/supplier/Supplier'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addSupplier,
      guard: AuthProtectSupplierAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/supplier/AddSupplier'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.supplierDetails.view,
      guard: AuthProtectSupplierViewEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/supplier/details/SupplierView'))
    },

    // RETAILER
    {
      exact: true,
      path: PATH_VERSION3.general.retailer,
      guard: AuthProtectRetailer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer/Retailers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addRetailer,
      guard: AuthProtectRetailerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer/add-retailer/AddRetailer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.retailerDetails.view,
      guard: AuthProtectRetailerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer/view-retailer/ViewRetailer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.retailerDetails.moduleSetting,
      guard: AuthProtectRetailerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer/view-retailer/RetailerModuleSetting'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.retailerDetails.edit,
      guard: AuthProtectRetailerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer/edit-retailer/EditRetailer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addRetailerBulkUpload,
      guard: AuthProtectRetailerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer/add-retailer/AddRetailerBulkUpload'))
    },

    // REPORTS,
    {
      exact: true,
      path: PATH_VERSION3.general.reports,
      guard: AuthProtectReports,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/Reports'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsProductSalesSummary,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/sales/ReportsProductSalesSummary'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsProductSalesDetailed,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/sales/ReportsProductSalesDetailed'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsProductPriceList,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/sales/ReportsProductPriceList'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsSalesOrderSummary,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/sales/ReportsSalesOrderSummary'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsCustomerOrderSummary,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/sales/ReportsCustomerOrderSummary'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsVendorList,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/purchasing/ReportsVendorList'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsPurchaseOrderSummary,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/purchasing/ReportsPurchaseOrderSummary'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsPurchaseOrderDetails,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/purchasing/ReportsPurchaseOrderDetails'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsVendorProductList,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/purchasing/ReportsVendorProductList'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsCustomerPaymentSummary,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/payment/ReportsCustomerPaymentSummary'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsCustomerPaymentByOrder,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/payment/ReportsCustomerPaymentByOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.reportsCustomerPaymentDetails,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports/payment/ReportsCustomerPaymentDetails'))
    },

    // REPORTS FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.reportsFinance,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/reports-finance/Reports'))
    },

    // TYPE
    {
      exact: true,
      path: PATH_VERSION3.general.inboundType,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-type/InboundType'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addInboundType,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-type/add-new/AddInboundType'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundTypeDetail.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-type/details/InboundTypeView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundTypeDetail.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-type/details/InboundTypeEdit'))
    },

    // CARRIER
    {
      exact: true,
      path: PATH_VERSION3.general.inboundCarrier,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-carrier/InboundCarrier'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addInboundCarrier,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-carrier/AddInboundCarrier'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundCarrierDetail.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-carrier/details/InboundCarrierView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundCarrierDetail.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-carrier/details/InboundCarrierEdit'))
    },

    // PRODUCT CATEGORY
    {
      exact: true,
      path: PATH_VERSION3.general.productCategory,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product-category/ProductCategory'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addProductCategory,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product-category/AddProductCategory'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productCategoryDetails.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product-category/details/ProductCategoryView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.productCategoryDetails.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/product-category/details/ProductCategoryEdit'))
    },

    // COURIER MANAGEMENT
    {
      exact: true,
      path: PATH_VERSION3.general.courierManagement,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/courier-management/CourierManagement'))
    },  
    {
      exact: true,
      path: PATH_VERSION3.general.courierManagementDetails.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/courier-management/details/CourierManagementView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.courierManagementDetails.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/courier-management/details/CourierManagementEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addCourierManagement,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/courier-management/AddCourierManagement'))
    },

    // COO FILES
    {
      exact: true,
      path: PATH_VERSION3.general.cooFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/coo-files/COOFiles'))
    },
    // LOI FILES
    {
      exact: true,
      path: PATH_VERSION3.general.loiFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/loi-files/LOIFiles'))
    },
    // COC FILES
    { 
      exact: true,
      path: PATH_VERSION3.general.cocFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/coc-files/COCFiles'))
    },
    // BL FILES
    {
      exact: true,
      path: PATH_VERSION3.general.blFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/bl-files/BLFiles'))
    },
    // PL FILES
    {
      exact: true,
      path: PATH_VERSION3.general.plFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/pl-files/PLFiles'))
    },
    // PO FILES
    {
      exact: true,
      path: PATH_VERSION3.general.poFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/po-files/POFiles'))
    },

    // EMAIL
    {
      exact: true,
      path: PATH_VERSION3.general.email,
      guard: AuthProtectEmailSettings,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/email/EmailView'))
    },

    // RMA
    {
      exact: true,
      path: PATH_VERSION3.general.rma,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/rma/RMA'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addRMA,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/rma/AddRMA'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.rmaDetail.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/rma/rma-details/EditRMA'))
    },
    // OFFER SALES V2
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesV2,
      guard: AuthProtectOfferSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-v2/Offer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.offerSalesV2Details.view,
      guard: AuthProtectOfferSales,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/offer-sales-v2/details/ViewOffer'))
    },

    // RETAILER GROUP
    {
      exact: true,
      path: PATH_VERSION3.general.retailerGroup,
      guard: AuthProtectRetailer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer-group/RetailerGroup'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addRetailerGroup,
      guard: AuthProtectRetailerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer-group/AddRetailerGroup'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.retailerGroupDetails.view,
      guard: AuthProtectRetailerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer-group/details/ViewRetailerGroup'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.retailerGroupDetails.edit,
      guard: AuthProtectRetailerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/retailer-group/details/EditRetailerGroup.js'))
    },

    // CONSIGNMENT SKU ORDER
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrder,
      guard: AuthProtectConsignmentSKUOrder,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentSKUOrder/ConsignmentSKUOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderDetails.new,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentSKUOrder/details/ConsignmentSKUOrderNew'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderDetails.addProduct,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentSKUOrder/add-product/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderDetails.view,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentSKUOrder/details/ConsignmentSKUOrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderDetails.viewRetailer,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentSKUOrder/details/ConsignmentSKUOrderViewRetailer'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderDetails.edit,
      guard: AuthProtectConsignmentSKUOrderEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentSKUOrder/details/ConsignmentSKUOrderEdit'))
    },

    // CONSIGNMENT SKU ORDER V2
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderV2,
      guard: AuthProtectConsignmentSKUOrder,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignment-sku-order-v2/ConsignmentSKUOrderV2'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderV2Details.addProduct,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignment-sku-order-v2/add-product/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderV2Details.view,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignment-sku-order-v2/details/ConsignmentSKUOrderV2View'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderV2Details.new,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignment-sku-order-v2/details/ConsignmentSKUOrderV2New'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderV2Details.edit,
      guard: AuthProtectConsignmentSKUOrderEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignment-sku-order-v2/details/ConsignmentSKUOrderV2Edit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentSKUOrderV2Details.viewRetailer,
      guard: AuthProtectConsignmentSKUOrderView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignment-sku-order-v2/details/ConsignmentSKUOrderV2ViewRetailer'))
    },

    // CONSIGNMENT PRODUCT
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProduct,
      guard: AuthProtectConsignmentProducts,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addConsignmentProduct,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/add-new/AddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductDetails.view,
      guard: AuthProtectConsignmentProductsView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/view/ViewProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductDetails.edit,
      guard: AuthProtectConsignmentProductsEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/edit/EditProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductBulkUpload.root,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/bulk-upload/BulkUpload'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductBulkUpload.addConsignmentProductBulkUpload,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/bulk-upload/BulkUploadAddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductBulkUpload.editConsignmentProductBulkUpload,
      guard: AuthProtectConsignmentProductsEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/bulk-upload/BulkUploadEditProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductImagesBulkUpload.root,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/images-bulk-upload/ImagesBulkUpload'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductImagesBulkUpload.identicalImages,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/images-bulk-upload/ImagesBulkUploadIdentical'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductImagesBulkUpload.differentImages,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/images-bulk-upload/ImagesBulkUploadDifferent'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductCatalogue.root,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/product-catalogue/GenerateProductCatalogue'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentProductCatalogue.chooseProduct,
      guard: AuthProtectConsignmentProductsAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentProduct/product-catalogue/ChooseProductList'))
    },

    // CONSIGNMENT REPORT
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentReport,
      guard: AuthProtectConsignmentReport,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentReport/ConsignmentReport'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentReportDetails.view,
      guard: AuthProtectConsignmentReportView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentReport/details/ConsignmentReportView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentReportNew.sale,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentReport/new/AddConsignmentSale'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentReportNew.addProduct,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentReport/new/ConsignmentAddProduct'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentReportNew.auditStock,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentReport/new/AddConsignmentAudit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentReportNew.addProductAuditStock,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentReport/new/audit-stock/ConsignmentAddProduct'))
    },

    // CONSIGNMENT STOCK RETURNABLE
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentStockReturnable,
      guard: AuthProtectConsignmentStockReturnable,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentStockReturnable/StockReturnable'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addConsignmentStockReturnable.new,
      guard: AuthProtectConsignmentStockReturnableAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentStockReturnable/new/AddStockReturnable'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.addConsignmentStockReturnable.addProduct,
      guard: AuthProtectConsignmentStockReturnableAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentStockReturnable/new/add-product/Product'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentStockReturnableDetails.view,
      guard: AuthProtectConsignmentStockReturnableView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentStockReturnable/details/ConsignmentStockReturnableView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentStockReturnableDetails.edit,
      guard: AuthProtectConsignmentStockReturnableEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentStockReturnable/details/ConsignmentStockReturnableEdit'))
    },

    // RMA - V2
    {
      exact: true,
      path: PATH_VERSION3.general.rmaV2,
      guard: AuthProtectRMA,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/rma-v2/RMA'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.rmaV2Details.view,
      guard: AuthProtectRMAView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/rma-v2/details/RMAView'))
    },

    // CONSIGNMENT RETAILER STOCK
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentRetailerStock,
      guard: AuthProtectRetailerStock,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentRetailerStock/ConsignmentRetailerStock'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentRetailerStockDetails.view,
      guard: AuthProtectRetailerStockView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentRetailerStock/details/ConsignmentRetailerStockView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.consignmentRetailerStockDetails.skuHistory,
      guard: AuthProtectRetailerStockView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/consignmentRetailerStock/details/SKUHistory'))
    },

    // COMPANY ACCOUNT
    {
      exact: true,
      path: PATH_VERSION3.general.companyAccount,
      guard: AuthProtectCompanyAccountView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/company-account/CompanyAccount'))
    },

    //------------------------------ADMIN COMBINED MODULE VERSION--------------------------------//
    // ORDER ADMIN - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuSales.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/sales/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuSales.add,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/sales/AddOrder'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuSales.addBulkUpload,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/sales/AddOrderBulkUpload'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuSales.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/sales/details/OrderView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuSales.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/sales/details/OrderEdit'))
    },

    // ORDER ADMIN - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuFinance.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/finance/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuFinance.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/finance/details/OrderEdit'))
    },

    // ORDER ADMIN - LOGISTIC
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuLogistic.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/logistic/Order'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.orderAdminSubMenuLogistic.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/order-admin/logistic/details/OrderView'))
    },

    // INVOICE ADMIN - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceAdminSubMenuSales.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoice-admin/sales/Invoice'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceAdminSubMenuSales.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoice-admin/sales/InvoiceView'))
    },

    // INVOICE ADMIN - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceAdminSubMenuFinance.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoice-admin/finance/Invoice'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceAdminSubMenuFinance.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoice-admin/finance/details/InvoiceView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceAdminSubMenuFinance.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoice-admin/finance/details/InvoiceEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.invoiceAdminSubMenuFinance.bulkUpload,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/invoice-admin/finance/InvoiceBulkUpload'))
    },

    // INBOUND ADMIN - SALES
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuSales.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/sales/Inbound'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuSales.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/sales/InboundView'))
    },

    // INBOUND ADMIN - FINANCE
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuFinance.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/finance/Import'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuFinance.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/finance/ImportView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuFinance.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/finance/ImportEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuFinance.new,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/finance/ImportNew'))
    },

    // INBOUND ADMIN - LOGISTIC
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuLogistic.root,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/logistic/Inbound'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuLogistic.add,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/logistic/AddInbound'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuLogistic.details.view,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/logistic/details/InboundView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuLogistic.details.edit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/logistic/details/InboundEdit'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuLogistic.details.editGeneralInfo,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/logistic/details/InboundEditGeneralInfo'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.inboundAdminSubMenuLogistic.details.editFiles,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/inbound-admin/logistic/details/InboundEditFiles'))
    },

    // SINGLE SIDED RETAILERS
    {
      exact: true,
      path: PATH_VERSION3.general.singleSidedRetailers.root,
      guard: AuthProtectSingleSidedRetailer,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/single-sided-retailers/SingleSidedRetailers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.singleSidedRetailers.new,
      guard: AuthProtectSingleSidedRetailerAdd,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/single-sided-retailers/AddSingleSidedRetailers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.singleSidedRetailers.details.view,
      guard: AuthProtectSingleSidedRetailerView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/single-sided-retailers/details/ViewSingleSidedRetailers'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.singleSidedRetailers.details.edit,
      guard: AuthProtectSingleSidedRetailerEdit,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/single-sided-retailers/details/EditSingleSidedRetailers'))
    },

    // SUB DISTRIBUTOR
    {
      exact: true,
      path: PATH_VERSION3.general.subdistributor.root,
      guard: AuthProtectSubDistributor,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/sub-distributor/SubDistributor'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.subdistributor.details.view,
      guard: AuthProtectSubDistributorView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/sub-distributor/SubDistributorView'))
    },
    {
      exact: true,
      path: PATH_VERSION3.general.subdistributor.details.moduleSetting,
      guard: AuthProtectSubDistributorView,
      component: lazy(() => import('src/views/AdminVersion3DashboardGroup/sub-distributor/SubDistributorModuleSetting'))
    }
  ]
}

export default AppAdminV3Routes