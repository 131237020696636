// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  docs: '/docs',
  sales: '/sales',
  admin: '/admin',
  finance: '/finance',
  logistic: '/logistic',
  adminKC: '/adminKC',
  adminRegister: '/admin-register',
  version3: '/module',
  accountDisabled: '/account-disabled',
  subdistributor: '/subdistributor',
  homepage: '/'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login/:token'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify'),
    homepage: ROOTS.homepage
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  app: {
    mail: {
      root: path(ROOTS.app, '/mail'),
      all: path(ROOTS.app, '/mail/all'),
      labels: [
        path(ROOTS.app, '/mail/label/:customLabel/:mailId?'),
        path(ROOTS.app, '/mail/:systemLabel/:mailId?')
      ]
    },
    chat: {
      root: path(ROOTS.app, '/chat'),
      new: path(ROOTS.app, '/chat/new'),
      conversation: [
        path(ROOTS.app, '/chat/new'),
        path(ROOTS.app, '/chat/:conversationKey')
      ]
    },
    calendar: path(ROOTS.app, '/calendar')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      profile: path(ROOTS.app, '/management/user/profile'),
      cards: path(ROOTS.app, '/management/user/card'),
      list: path(ROOTS.app, '/management/user/list'),
      account: path(ROOTS.app, '/management/user/account')
    },
    eCommerce: {
      root: path(ROOTS.app, '/management/e-commerce'),
      products: path(ROOTS.app, '/management/e-commerce/products'),
      product: path(ROOTS.app, '/management/e-commerce/product/:name'),
      productById: path(
        ROOTS.app,
        '/management/e-commerce/product/nike-air-force-1-ndestrukt'
      ),
      list: path(ROOTS.app, '/management/e-commerce/list'),
      checkout: path(ROOTS.app, '/management/e-commerce/checkout'),
      invoice: path(ROOTS.app, '/management/e-commerce/invoice')
    },
    blog: {
      root: path(ROOTS.app, '/management/blog'),
      post: path(ROOTS.app, '/management/blog/post/:title'),
      postById: path(
        ROOTS.app,
        '/management/blog/post/portfolio-review-is-this-portfolio-too-creative'
      ),
      newPost: path(ROOTS.app, '/management/blog/new-post')
    }
  },
  foundations: {
    root: path(ROOTS.app, '/foundations'),
    colors: path(ROOTS.app, '/foundations/colors'),
    typography: path(ROOTS.app, '/foundations/typography'),
    shadows: path(ROOTS.app, '/foundations/shadows'),
    grid: path(ROOTS.app, '/foundations/grid'),
    icons: path(ROOTS.app, '/foundations/icons')
  },
  components: {
    root: path(ROOTS.app, '/components'),
    accordion: path(ROOTS.app, '/components/accordion'),
    alert: path(ROOTS.app, '/components/alert'),
    autocomplete: path(ROOTS.app, '/components/autocomplete'),
    avatar: path(ROOTS.app, '/components/avatars'),
    badge: path(ROOTS.app, '/components/badges'),
    breadcrumbs: path(ROOTS.app, '/components/breadcrumbs'),
    buttons: path(ROOTS.app, '/components/buttons'),
    chip: path(ROOTS.app, '/components/chips'),
    dialog: path(ROOTS.app, '/components/dialogs'),
    textfield: path(ROOTS.app, '/components/text-fields'),
    label: path(ROOTS.app, '/components/labels'),
    lists: path(ROOTS.app, '/components/lists'),
    menu: path(ROOTS.app, '/components/menu'),
    pagination: path(ROOTS.app, '/components/pagination'),
    pickers: path(ROOTS.app, '/components/pickers'),
    popover: path(ROOTS.app, '/components/popover'),
    progress: path(ROOTS.app, '/components/progress'),
    rating: path(ROOTS.app, '/components/rating'),
    selectionControls: path(ROOTS.app, '/components/selection-controls'),
    snackbar: path(ROOTS.app, '/components/snackbars'),
    slider: path(ROOTS.app, '/components/slider'),
    stepper: path(ROOTS.app, '/components/steppers'),
    tabs: path(ROOTS.app, '/components/tabs'),
    table: path(ROOTS.app, '/components/table'),
    timeline: path(ROOTS.app, '/components/timeline'),
    tooltip: path(ROOTS.app, '/components/tooltips'),
    transferList: path(ROOTS.app, '/components/transfer-list'),
    treeView: path(ROOTS.app, '/components/tree-view'),

    // Extra
    chart: {
      root: path(ROOTS.app, '/extra-components/chart'),
      apexcharts: path(ROOTS.app, '/extra-components/chart/apexcharts'),
      recharts: path(ROOTS.app, '/extra-components/chart/recharts')
    },
    map: {
      root: path(ROOTS.app, '/extra-components/map'),
      google: path(ROOTS.app, '/extra-components/map/googlemap'),
      mapbox: path(ROOTS.app, '/extra-components/map/mapbox')
    },
    editor: path(ROOTS.app, '/extra-components/editor'),
    copyToClipboard: path(ROOTS.app, '/extra-components/copy-to-clipboard'),
    upload: path(ROOTS.app, '/extra-components/upload'),
    carousel: path(ROOTS.app, '/extra-components/carousel'),
    multiLanguage: path(ROOTS.app, '/extra-components/multi-language')
  }
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, '/introduction'),
  started: path(ROOTS.docs, '/getting-started'),
  // Theme UI
  color: path(ROOTS.docs, '/color'),
  typography: path(ROOTS.docs, '/typography'),
  icon: path(ROOTS.docs, '/icon'),
  shadows: path(ROOTS.docs, '/shadows'),
  components: path(ROOTS.docs, '/components'),
  tips: path(ROOTS.docs, '/tips'),

  // Development
  routing: path(ROOTS.docs, '/routing'),
  environmentVariables: path(ROOTS.docs, '/environment-variables'),
  stateManagement: path(ROOTS.docs, '/state-management'),
  apiCalls: path(ROOTS.docs, '/api-calls'),
  analytics: path(ROOTS.docs, '/analytics'),
  authentication: path(ROOTS.docs, '/authentication'),
  multiLanguage: path(ROOTS.docs, '/multi-language'),
  lazyload: path(ROOTS.docs, '/lazyload-image'),
  formHelper: path(ROOTS.docs, '/form-helper'),

  // Changelog
  support: path(ROOTS.docs, '/support'),
  changelog: path(ROOTS.docs, '/changelog')
};

export const PATH_SALES = {
  root: ROOTS.sales,
  general: {
    root: path(ROOTS.sales, '/dashboard'),
    dashboard: path(ROOTS.sales, '/dashboard'),
    //PRE ORDER
    preOrder: path(ROOTS.sales, '/pre-order'),
    addPreOrder: path(ROOTS.sales, '/pre-order/new'),
    preOrderDetails: {
      view: path(ROOTS.sales, '/pre-order/details/:preOrderID?')
    },

    //OFFERS
    offers: path(ROOTS.sales, '/offers'),
    addOffers: path(ROOTS.sales, '/offers/new'),
    offerDetails: {
      view: path(ROOTS.sales, '/offers/details/:offerID?'),
      product: path(ROOTS.sales, '/offers/details/:offerID?/:productID?')
    },
    addOffersDetail: {
      addProduct: path(ROOTS.sales, '/offers/new/add-product')
    },
    editOffers: path(ROOTS.sales, '/offers/edit/:offerID?'),

    //PRODUCTS
    products: path(ROOTS.sales, '/products'),
    addProduct: path(ROOTS.sales, '/products/new'),
    productDetails: {
      view: path(ROOTS.sales, '/products/details/:productID?')
    },

    //ORDER
    order: path(ROOTS.sales, '/order'),
    addOrder: path(ROOTS.sales, '/order/new'),
    orderDetails: {
      view: path(ROOTS.sales, '/order/details/:invoiceID?')
    },

    //PAYMENT
    payment: path(ROOTS.sales, '/payment'),
    addPayment: path(ROOTS.sales, '/payment/new'),
    paymentDetails: {
      view: path(ROOTS.sales, '/payment/details/:paymentID?')
    },

    //SHIPMENT
    shipment: path(ROOTS.sales, '/shipment'),
    addShipment: path(ROOTS.sales, '/shipment/new'),
    shipmentDetails:  {
      view: path(ROOTS.sales, '/shipment/details/:shipmentID?')
    },
  
    //RETAILER
    retailer: path(ROOTS.sales, '/retailer'),
    addRetailer: path(ROOTS.sales, '/retailer/new'),
    retailerDetails: {
      view: path(ROOTS.sales, '/retailer/details/:retailerID?'),
      edit: path(ROOTS.sales, '/retailer/details/:retailerID?/edit')
    },

    //SUPPLIER
    supplier: path(ROOTS.sales, '/supplier'),
    addSupplier: path(ROOTS.sales, '/supplier/new'),
    supplierDetails: {
      view: path(ROOTS.sales, '/supplier/details/:supplierID?')
    },

    //TAGS
    tags: path(ROOTS.sales, '/tags'),
    addTags: path(ROOTS.sales, '/tags/new'),
    tagsDetails: {
      view: path(ROOTS.sales, '/tags/details/:tagID?')
    },

    //EMAIL
    email: path(ROOTS.sales, '/email')

    //PURCHASE ORDER
    //purchaseOrder: path(ROOTS.sales, '/purchase-order'),
    //addPurchaseOrder: path(ROOTS.sales, '/purchase-order/new')
  }
}

export const PATH_ADMIN = {
  root: ROOTS.admin,
  general: {
    root: path(ROOTS.admin, '/dashboard'),
    dashboard: path(ROOTS.admin, '/dashboard'),

    //OFFERS
    offers: path(ROOTS.admin, '/offers'),
    addOffers: path(ROOTS.admin, '/offers/new'),
    offerDetails: {
      view: path(ROOTS.admin, '/offers/details/:offerID?'),
      product: path(ROOTS.admin, '/offers/details/:offerID?/:productID?')
    },
    addOffersDetail: {
      addProduct: path(ROOTS.admin, '/offers/new/add-product')
    },
    editOffers: path(ROOTS.admin, '/offers/edit/:offerID?'),

    //PRODUCTS
    products: path(ROOTS.admin, '/products'),
    addProduct: path(ROOTS.admin, '/products/new'),
    productDetails: {
      view: path(ROOTS.admin, '/products/details/:productID?')
    },

    //PRE ORDER
    preOrder: path(ROOTS.admin, '/pre-order'),
    addPreOrder: path(ROOTS.admin, '/pre-order/new'),
    preOrderDetails: {
      view: path(ROOTS.admin, '/pre-order/details/:preOrderID?')
    },

    //PURCHASE ORDER
    purchaseOrder: path(ROOTS.admin, '/purchase-order'),
    addPurchaseOrder: path(ROOTS.admin, '/purchase-order/new'),
    purchaseOrderDetails: {
      view: path(ROOTS.admin, '/purchase-order/details/:purchaseOrderID?')
    },

    //ORDER
    order: path(ROOTS.admin, '/order'),
    addOrder: path(ROOTS.admin, '/order/new'),
    orderDetails: {
      view: path(ROOTS.admin, '/order/details/:invoiceID?')
    },

    //PAYMENT
    payment: path(ROOTS.admin, '/payment'),
    addPayment: path(ROOTS.admin, '/payment/new'),
    paymentDetails: {
      view: path(ROOTS.admin, '/payment/details/:paymentID?')
    },

    //SHIPMENT
    shipment: path(ROOTS.admin, '/shipment'),
    addShipment: path(ROOTS.admin, '/shipment/new'),
    shipmentDetails:  {
      view: path(ROOTS.admin, '/shipment/details/:shipmentID?')
    },

    //RETAILER
    retailer: path(ROOTS.admin, '/retailer'),
    addRetailer: path(ROOTS.admin, '/retailer/new'),
    retailerDetails: {
      view: path(ROOTS.admin, '/retailer/details/:retailerID?'),
      edit: path(ROOTS.admin, '/retailer/details/:retailerID?/edit')
    },

    
    //SUPPLIER
    supplier: path(ROOTS.admin, '/supplier'),
    addSupplier: path(ROOTS.admin, '/supplier/new'),
    supplierDetails: {
      view: path(ROOTS.admin, '/supplier/details/:supplierID?')
    },


    //ROLE
    role: path(ROOTS.admin, '/role'),
    addRole: path(ROOTS.admin, '/role/new'),
    roleDetails: {
      view: path(ROOTS.admin, '/role/details/:roleID?')
    },
    
    //USER
    user: path(ROOTS.admin, '/user'),
    addUser: path(ROOTS.admin, '/user/new'),
    userDetails: {
      view: path(ROOTS.admin, '/user/details/:userID?')
    },

    //DISTRIBUTOR
    distributor: path(ROOTS.admin, '/distributor'),
    addDistributor: path(ROOTS.admin, '/distributor/new'),

    //EMAIL
    email: path(ROOTS.admin, '/email'),

    //REPORTS
    reports: path(ROOTS.admin, '/reports'),

    // REPORTS - SALES
    reportsProductSalesSummary: path(ROOTS.admin, '/reports/view/product-sales-summary'),
    reportsProductSalesDetailed: path(ROOTS.admin, '/reports/view/product-sales-detailed'),
    reportsProductPriceList: path(ROOTS.admin, '/reports/view/product-price-list'),
    reportsSalesOrderSummary: path(ROOTS.admin, '/reports/view/sales-order-summary'),
    reportsCustomerOrderSummary: path(ROOTS.admin, '/reports/view/customer-order-summary'),

    // REPORTS - VENDOR
    reportsVendorList: path(ROOTS.admin, '/reports/view/vendor-list'),
    reportsPurchaseOrderSummary: path(ROOTS.admin, '/reports/view/purchase-order-summary'),
    reportsPurchaseOrderDetails: path(ROOTS.admin, '/reports/view/purchase-order-details'),
    reportsVendorProductList: path(ROOTS.admin, '/reports/view/vendor-product-list'),

    // REPORTS - PAYMENT
    reportsCustomerPaymentSummary: path(ROOTS.admin, '/reports/view/customer-payment-summary'),
    reportsCustomerPaymentByOrder: path(ROOTS.admin, '/reports/view/customer-payment-by-order'),
    reportsCustomerPaymentDetails: path(ROOTS.admin, '/reports/view/customer-payment-details'),
    
    // COSTING 
    costingView: path(ROOTS.admin, '/costing'),
    costingEdit: path(ROOTS.admin, '/costing/edit'),
    costingNew: path(ROOTS.admin, '/costing/new'),

  },
  version3: {
    // VERSION 3 - MODULES - ALL MODULES WILL BE TEMPORARY ADDED UNDER ADMIN DISTRIBUTOR

    // Order V3 - SALES
    orderSales: path(ROOTS.admin, '/order-sales-V3'),
    addOrderSales: path(ROOTS.admin, '/order-sales-v3/new'),
    orderSalesDetails: {
      view: path(ROOTS.admin, '/order-sales-v3/details/:invoiceID?'),
      edit: path(ROOTS.admin, '/order-sales-v3/details/:invoiceID?/edit')
    },

    // Order V3 - SALES MANAGER
    orderSalesManager: path(ROOTS.admin, '/order-sales-manager-V3'),
    addOrderSalesManager: path(ROOTS.admin, '/order-sales-manager-v3/new'),
    orderSalesManagerDetails: {
      view: path(ROOTS.admin, '/order-sales-manager-v3/details/:invoiceID?'),
      edit: path(ROOTS.admin, '/order-sales-manager-v3/details/:invoiceID?/edit')
    },

    // Order V3 - FINANCE
    orderFinance: path(ROOTS.admin, '/order-finance-V3'),
    orderFinanceDetails: {
      view: path(ROOTS.admin, '/order-finance-v3/details/:invoiceID?')
    },

    // PAYMENT V3 - FINANCE
    paymentFinance: path(ROOTS.admin, '/payment-finance-V3'),
    paymentFinanceDetails: {
      view: path(ROOTS.admin, '/payment-finance-v3/details/:paymentID?')
    },

    // INVOICE V3 - FINANCE
    invoiceFinance: path(ROOTS.admin, '/invoice-finance-V3'),
    invoiceFinanceDetails: {
      view: path(ROOTS.admin, '/invoice-finance-v3/details/:invoiceID?'),
      edit: path(ROOTS.admin, '/invoice-finance-v3/details/:invoiceID?/edit')
    },

    // INVOICE V3 - FINANCE MANAGER
    invoiceFinanceManager: path(ROOTS.admin, '/invoice-finance-manager-V3'),
    invoiceFinanceManagerDetails: {
      view: path(ROOTS.admin, '/invoice-finance-manager-v3/details/:invoiceID?'),
      edit: path(ROOTS.admin, '/invoice-finance-manager-v3/details/:invoiceID?/edit')
    },

    // INVOICE V3 - SALES
    invoiceSales: path(ROOTS.admin, '/invoice-sales-v3'),
    invoiceSalesDetails: {
      view: path(ROOTS.admin, '/invoice-sales-v3/details/:invoiceID?')
    },

    // TRACKING V3 - SALES
    trackingSales: path(ROOTS.admin, '/tracking-sales-v3'),
    trackingSalesDetails: {
      view: path(ROOTS.admin, '/tracking-sales-v3/details/:invoiceID?')
    },

    // TRACKING V3 - LOGISTIC
    trackingLogistic: path(ROOTS.admin, '/tracking-logistic-v3'),
    trackingLogisticDetails: {
      view: path(ROOTS.admin, '/tracking-logistic-v3/details/:invoiceID?'),
      edit: path(ROOTS.admin, '/tracking-logistic-v3/details/:invoiceID?/edit')
    },


    // INBOUND V3 - LOGISTIC
    inboundLogistic: path(ROOTS.admin, '/inbound-logistic-v3'),
    addInboundLogistic: path(ROOTS.admin, '/inbound-logistic-v3/new'),
    inboundLogisticDetails: {
      view: path(ROOTS.admin, '/inbound-logistic-v3/details/:inboundID?'),
      edit: path(ROOTS.admin, '/inbound-logistic-v3/details/:inboundID?/edit')
    },

    // INBOUND V3 - SALES
    inboundSales: path(ROOTS.admin, '/inbound-sales-v3'),
    inboundSalesView: path(ROOTS.admin, '/inbound-sales-v3/details/:inboundID?'),

    // IMPORT V3 - FINANCE
    importFinance: path(ROOTS.admin, '/import-finance-v3'),
    importFinanceDetails: {
      view: path(ROOTS.admin, '/import-finance-v3/details/:inboundID?'),
      edit: path(ROOTS.admin, '/import-finance-v3/details/:inboundID?/edit'),
    },
    

    // OFFER V3 - SALES
    offerSales: path(ROOTS.admin, '/offer-sales-v3'),
    addOfferSales: path(ROOTS.admin, '/offer-sales-v3/new'),
    addOffersSalesDetail: {
      addProduct: path(ROOTS.admin, '/offer-sales-v3/new/add-product')
    },
    offerSalesDetails: {
      view: path(ROOTS.admin, '/offer-sales-v3/details/:offerID?')
    },

    // OFFER V3 - SALES MANAGER
    offerSalesManager: path(ROOTS.admin, '/offer-sales-manager-v3'),
    addOfferSalesManager: path(ROOTS.admin, '/offer-sales-manager-v3/new'),
    addOfferSalesManagerDetail: {
      addProduct: path(ROOTS.admin, '/offer-sales-manager-v3/new/add-product')
    },
    offerSalesManagerDetails: {
      view: path(ROOTS.admin, '/offer-sales-manager-v3/details/:offerID?')
    },

    // PRE ORDER V3 - SALES
    preOrderSales: path(ROOTS.admin, '/preorder-sales-v3'),
    preOrderSalesDetails: {
      view: path(ROOTS.admin, '/preorder-sales-v3/details/:preOrderID?'),
    },

    // PRE ORDER V3 - SALES MANAGER
    preOrderSalesManager: path(ROOTS.admin, '/preorder-sales-manager-v3'),
    addPreOrderSalesManager: path(ROOTS.admin, '/preorder-sales-manager-v3/new'),
    preOrderSalesManagerDetails: {
      view: path(ROOTS.admin, '/preorder-sales-manager-v3/details/:preOrderID?'),
    },
    
    // PURCHASE ORDER V3 - BUYER
    purchaseOrderBuyer: path(ROOTS.admin, '/purchase-order-buyer-v3'),
    purchaseOrderBuyerDetails: {
      view: path(ROOTS.admin, '/purchase-order-buyer-v3/details/:purchaseOrderID?'),
      edit: path(ROOTS.admin, '/purchase-order-buyer-v3/details/:purchaseOrderID?/edit')
    },
    addPurchaseOrderBuyer: path(ROOTS.admin, '/purchase-order-buyer-v3/new'),

    // CLOSED OFFERS V3 - BUYER
    closedOffersBuyer: path(ROOTS.admin, '/closed-offers-buyer-v3'),
    closedOffersBuyerDetails: {
      view: path(ROOTS.admin, '/closed-offers-buyer-v3/details/:offerID?')
    },

    // SKU V3 - BUYER
    skuBuyer: path(ROOTS.admin, '/sku-buyer-v3')
  }
}

export const PATH_VERSION3 = {
  root: ROOTS.version3,
  general: {
    root: path(ROOTS.version3, '/dashboard'),

    // DASHBOARD - INVOICES . PREVIOUSLY KNOWN AS RESTOCKS
    dashboardRestocks: path(ROOTS.version3, '/dashboard/invoices'),
    
    // USER V3 - ADMIN
    user: path(ROOTS.version3, '/user'),
    addUser: path(ROOTS.version3, '/user/new'),
    userDetails: {
      view: path(ROOTS.version3, '/user/details/:userID?')
    },

    // NOTIFICATIONS
    notifications: path(ROOTS.version3, '/notifications'),
    notificationDetail: {
      view: path(ROOTS.version3, '/notifications/details/:notificationID?')
    },

    // PROFILE
    profile: path(ROOTS.version3, '/profile'),
    changePassword: path(ROOTS.version3, '/profile/change-password'),
 
    // AGREEMENT TERMS
    agreementTerms: path(ROOTS.version3, '/agreement-terms'),
    addAgreementTerms: path(ROOTS.version3, '/agreement-terms/new'),
    agreementTermsDetails: {
      edit: path(ROOTS.version3, '/agreement-terms/details/:agreementTermID?/edit')
    },

    // DISTRIBUTOR PROFILE
    distributorProfile: path(ROOTS.version3, '/distributor-profile'),
    distributorProfileDetails: {
      edit: path(ROOTS.version3, '/distributor-profile/:distributorProfileID?/edit')
    },

    // Order V3 - ALL ORDER
    allOrder: path(ROOTS.version3, '/all-order'),

    // Order V3 - SALES
    orderSales: path(ROOTS.version3, '/order-sales'),
    addOrderSales: path(ROOTS.version3, '/order-sales/new'),
    orderSalesDetails: {
      view: path(ROOTS.version3, '/order-sales/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/order-sales/details/:invoiceID?/edit')
    },
    addOrderSalesBulkUpload: path(ROOTS.version3, '/order-sales/new/bulk-upload'),

    // Order V3 - SALES MANAGER
    orderSalesManager: path(ROOTS.version3, '/order-sales-manager'),
    addOrderSalesManager: path(ROOTS.version3, '/order-sales-manager/new'),
    orderSalesManagerDetails: {
      view: path(ROOTS.version3, '/order-sales-manager/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/order-sales-manager/details/:invoiceID?/edit')
    },
    addOrderSalesManagerBulkUpload: path(ROOTS.version3, '/order-sales-manager/new/bulk-upload'),

    // ORDER V3 - CONSIGNMENT
    orderConsignment: path(ROOTS.version3, '/order-consignment'),
    addOrderConsignment: path(ROOTS.version3, '/order-consignment/new'),
    addOrderConsignmentBulkUpload:  path(ROOTS.version3, '/order-consignment/new/bulk-upload'),
    addOrderConsignmentBulkUploadSingle: path(ROOTS.version3, '/order-consignment/new/bulk-upload-single-route'),
    orderConsignmentDetails: {
      view: path(ROOTS.version3, '/order-consignment/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/order-consignment/details/:invoiceID?/edit')
    },

    // Order V3 - FINANCE
    orderFinance: path(ROOTS.version3, '/order-finance'),
    orderFinanceDetails: {
      view: path(ROOTS.version3, '/order-finance/details/:invoiceID?')
    },

    // Order V3 - LOGISTIC
    orderLogistic: path(ROOTS.version3, '/order-logistic'),
    orderLogisticDetails: {
      view: path(ROOTS.version3, '/order-logistic/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/order-logistic/details/:invoiceID?/edit')
    },

    // Order V3 - LOGISTIC MANAGER
    orderLogisticManager: path(ROOTS.version3, '/order-logistic-manager'),
    orderLogisticManagerDetails: {
      view: path(ROOTS.version3, '/order-logistic-manager/details/:invoiceID?')
    },

    // ORDER ISSUES - LOGISTIC
    orderIssuesLogistic: path(ROOTS.version3, '/order-issues-logistic'),
    orderIssuesLogisticDetails: {
      view: path(ROOTS.version3, '/order-issues-logistic/details/:issueID?')
    },

    // ORDER ISSUES - SALES
    orderIssuesSales: path(ROOTS.version3, '/order-issues-sales'),
    orderIssuesSalesDetails: {
      view: path(ROOTS.version3, '/order-issues-sales/details/:issueID?')
    },

    // ORDER ISSUES - FINANCE
    orderIssuesFinance: path(ROOTS.version3, '/order-issues-finance'),
    orderIssuesFinanceDetails: {
      view: path(ROOTS.version3, '/order-issues-finance/details/:issueID?')
    },

    // PAYMENT V3 - FINANCE
    paymentFinance: path(ROOTS.version3, '/payment-finance'),
    paymentFinanceDetails: {
      view: path(ROOTS.version3, '/payment-finance/details/:paymentID?')
    },

    // CREDIT NOTE
    creditNote: path(ROOTS.version3, '/credit-note'),
    creditNoteDetails: {
      view: path(ROOTS.version3, '/credit-note/details/:paymentID?')
    },

    // INVOICE V3 - FINANCE
    invoiceFinance: path(ROOTS.version3, '/invoice-finance'),
    invoiceFinanceDetails: {
      view: path(ROOTS.version3, '/invoice-finance/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/invoice-finance/details/:invoiceID?/edit')
    },
    invoiceFinanceBulkUpload: path(ROOTS.version3, '/invoice-finance/bulk-upload'),

    // INVOICE V3 - FINANCE MANAGER
    invoiceFinanceManager: path(ROOTS.version3, '/invoice-finance-manager'),
    invoiceFinanceManagerDetails: {
      view: path(ROOTS.version3, '/invoice-finance-manager/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/invoice-finance-manager/details/:invoiceID?/edit')
    },
    invoiceFinanceManagerBulkUpload: path(ROOTS.version3, '/invoice-finance-manager/bulk-upload'),

    // INVOICE V3 - SALES
    invoiceSales: path(ROOTS.version3, '/invoice-sales'),
    invoiceSalesDetails: {
      view: path(ROOTS.version3, '/invoice-sales/details/:invoiceID?')
    },

    // TRACKING V3 - SALES
    trackingSales: path(ROOTS.version3, '/tracking-sales'),
    trackingSalesDetails: {
      view: path(ROOTS.version3, '/tracking-sales/details/:invoiceID?')
    },

    // TRACKING V3 - LOGISTIC
    trackingLogistic: path(ROOTS.version3, '/tracking-logistic'),
    trackingLogisticDetails: {
      view: path(ROOTS.version3, '/tracking-logistic/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/tracking-logistic/details/:invoiceID?/edit')
    },


    // INBOUND V3 - LOGISTIC
    inboundLogistic: path(ROOTS.version3, '/inbound-logistic'),
    addInboundLogistic: path(ROOTS.version3, '/inbound-logistic/new'),
    inboundLogisticDetails: {
      view: path(ROOTS.version3, '/inbound-logistic/details/:inboundID?'),
      edit: path(ROOTS.version3, '/inbound-logistic/details/:inboundID?/edit'),
      editGeneralInfo: path(ROOTS.version3, '/inbound-logistic/details/:inboundID?/edit-general-info'),
      editFiles: path(ROOTS.version3, '/inbound-logistic/details/:inboundID?/edit-files')
    },

    // INBOUND V3 - SALES
    inboundSales: path(ROOTS.version3, '/inbound-sales'),
    inboundSalesView: path(ROOTS.version3, '/inbound-sales/details/:inboundID?'),

    // IMPORT V3 - FINANCE
    inboundFinance: path(ROOTS.version3, '/inbound-finance'),
    inboundFinanceDetails: {
      new: path(ROOTS.version3, '/inbound-finance/details/:inboundID?/new'),
      view: path(ROOTS.version3, '/inbound-finance/details/:inboundID?'),
      edit: path(ROOTS.version3, '/inbound-finance/details/:inboundID?/edit'),
    },


    // PRODUCT V3 
    product: path(ROOTS.version3, '/product'),
    addProduct: path(ROOTS.version3, '/product/new'),
    productBulkUpload: {
      root: path(ROOTS.version3, '/product/bulk-upload'),
      addProductBulkUpload: path(ROOTS.version3, '/product/bulk-upload/add-product'),
      editProductBulkUpload: path(ROOTS.version3, '/product/bulk-upload/edit-product'),
    },
    productImagesBulkUpload: {
      root: path(ROOTS.version3, '/product/images-bulk-upload'),
      identicalImages: path(ROOTS.version3, '/product/images-bulk-upload/identical-images'),
      differentImages: path(ROOTS.version3, '/product/images-bulk-upload/different-images'),
    },
    productCatalogue: {
      root: path(ROOTS.version3, '/product/generate-product-catalogue'),
      chooseProduct: path(ROOTS.version3, '/product/generate-product-catalogue/choose-product'),
    },
    productDetails: {
      view: path(ROOTS.version3, '/product/details/:productID?'),
      edit: path(ROOTS.version3, '/product/details/:productID?/edit'),
      skuOrder: path(ROOTS.version3, '/product/details/:productID?/sku-order')
    },

    // OFFER V3 - SALES
    offerSales: path(ROOTS.version3, '/offer-sales'),
    addOfferSales: path(ROOTS.version3, '/offer-sales/new'),
    addOffersSalesDetail: {
      addProduct: path(ROOTS.version3, '/offer-sales/new/add-product')
    },
    offerSalesDetails: {
      view: path(ROOTS.version3, '/offer-sales/details/:offerID?'),
      edit: path(ROOTS.version3, '/offer-sales/details/:offerID?/edit'),
      editProduct: path(ROOTS.version3, '/offer-sales/details/:offerID?/edit/edit-product/:productID?')
    },

    // OFFER V3 - SALES MANAGER
    offerSalesManager: path(ROOTS.version3, '/offer-sales-manager'),
    addOfferSalesManager: path(ROOTS.version3, '/offer-sales-manager/new'),
    addOfferSalesManagerDetail: {
      addProduct: path(ROOTS.version3, '/offer-sales-manager/new/add-product')
    },
    offerSalesManagerDetails: {
      view: path(ROOTS.version3, '/offer-sales-manager/details/:offerID?'),
      edit: path(ROOTS.version3, '/offer-sales-manager/details/:offerID?/edit'),
      editProduct: path(ROOTS.version3, '/offer-sales-manager/details/:offerID?/edit/edit-product/:productID?')
    },

    // OFFER V3 - SALES MANAGER - V2
    offerSalesManagerV2: path(ROOTS.version3, '/offer-sales-manager-version-2'),
    addOfferSalesManagerV2: path(ROOTS.version3, '/offer-sales-manager-version-2/new'),
    addOfferSalesManagerV2Sub: {
      addProduct: path(ROOTS.version3, '/offer-sales-manager-version-2/new/add-product') 
    },
    offerSalesManagerV2Details: {
      view: path(ROOTS.version3, '/offer-sales-manager-version-2/details/:offerID?'),
      viewMode2: path(ROOTS.version3, '/offer-sales-manager-version-2/details/:offerID?/view-mode-2'),
      viewRetailerList: path(ROOTS.version3, '/offer-sales-manager-version-2/details/:offerID?/view-retailer-list'),
      edit: path(ROOTS.version3, '/offer-sales-manager-version-2/details/:offerID?/edit'),
      manageRating: path(ROOTS.version3, '/offer-sales-manager-version-2/details/:offerID?/manage-rating'),
      manageRecommendedCartonQty: path(ROOTS.version3, '/offer-sales-manager-version-2/details/:offerID?/manage-recommended-carton-qty')
    },

    // OFFER V3 - ADMIN
    offerAdmin: path(ROOTS.version3, '/offer-admin'),
    addOfferAdmin: path(ROOTS.version3, '/offer-admin/new'),
    addOfferAdminSub: {
      addProduct: path(ROOTS.version3, '/offer-admin/new/add-product')
    },
    offerAdminDetails: {
      view: path(ROOTS.version3, '/offer-admin/details/:offerID?'),
      viewMode2: path(ROOTS.version3, '/offer-admin/details/:offerID?/view-mode-2'),
      viewRetailerList: path(ROOTS.version3, '/offer-admin/details/:offerID?/view-retailer-list'),
      edit: path(ROOTS.version3, '/offer-admin/details/:offerID?/edit'),
      manageRating: path(ROOTS.version3, '/offer-admin/details/:offerID?/manage-rating'),
      manageRecommendedCartonQty: path(ROOTS.version3, '/offer-admin/details/:offerID?/manage-recommended-carton-qty')
    },

    // OFFER V3 - SUPER ADMIN
    offerSuperAdmin: path(ROOTS.version3, '/offer-super-admin'),
    offerSuperAdminDetails: {
      view: path(ROOTS.version3, '/offer-super-admin/details/:offerID?'),
      edit: path(ROOTS.version3, '/offer-super-admin/details/:offerID?/edit')
    },

    // PRE ORDER V3 - SALES
    /**preOrderSales: path(ROOTS.version3, '/preorder-sales'),
    preOrderSalesDetails: {
      view: path(ROOTS.version3, '/preorder-sales/details/:preOrderID?'),
      edit: path(ROOTS.version3, '/preorder-sales/details/:preOrderID?/edit')
    },*/

    // PRE ORDER V4 - SALES // COPY MANAGER TO REGULAR SALES AND RENAME 
    // APP ADMINV3 ROUTES KNOWN AS PREORDERV2 AS FOLDER NAME CONCURRENT
    preOrderSales: path(ROOTS.version3, '/preorder-sales'),
    addPreOrderSales: path(ROOTS.version3, '/preorder-sales/new'),
    preOrderSalesDetails: {
      view: path(ROOTS.version3, '/preorder-sales/details/:preOrderID?'),
      edit: path(ROOTS.version3, '/preorder-sales/details/:preOrderID?/edit')
    },

    // PRE ORDER V3 - SALES MANAGER
    preOrderSalesManager: path(ROOTS.version3, '/preorder-sales-manager'),
    addPreOrderSalesManager: path(ROOTS.version3, '/preorder-sales-manager/new'),
    preOrderSalesManagerDetails: {
      preOrderListDetail: path(ROOTS.version3, '/preorder-sales-manager/details/:offerID?/preorder-list-detail'),
      viewPreOrder: path(ROOTS.version3, '/preorder-sales-manager/details/:offerObjID?/preorder-list-detail/view/:preOrderID?'),
      editPreOrder: path(ROOTS.version3, '/preorder-sales-manager/details/:offerObjID?/preorder-list-detail/view/:preOrderID?/edit'),
      addPreOrder: path(ROOTS.version3, '/preorder-sales-manager/new/:offerID?')

      // view: path(ROOTS.version3, '/preorder-sales-manager/details/:preOrderID?'),
      // edit: path(ROOTS.version3, '/preorder-sales-manager/details/:preOrderID?/edit')
    },

    // CLOSED OFFERS V3 - BUYER
    closedOffersBuyer: path(ROOTS.version3, '/closed-offers-buyer'),
    closedOffersBuyerDetails: {
      view: path(ROOTS.version3, '/closed-offers-buyer/details/:offerID?')
    },

    // CLOSED OFFERS V3 - VERSION 2 ROS - BUYER
    closedOffersBuyerV2: path(ROOTS.version3, '/closed-offers-buyer-version-2'),
    closedOffersBuyerV2Details: {
      new: path(ROOTS.version3, '/closed-offers-buyer-version-2/new/:offerID?'),
      view: path(ROOTS.version3, '/closed-offers-buyer-version-2/details/:offerID?'),
      viewPOBreakdown: path(ROOTS.version3, '/closed-offers-buyer-version-2/details/:offerID?/po-breakdown/:productID?'),
      edit: path(ROOTS.version3, '/closed-offers-buyer-version-2/details/:offerID?/edit')
    },

    // CLOSED OFFERS V3 - VERSION 2 ROS - BUYER MANAGER
    closedOffersBuyerManagerV2: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2'),
    closedOffersBuyerManagerV2Details: {
      new: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/new/:offerID?'),
      view: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?'),
      viewPOBreakdown: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?/po-breakdown/:productID?'),
      edit: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?/edit'),
      preOrderCompilation: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?/view-mode-2'),
      manageRating: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?/manage-rating'),
      manageRecommendedCartonQty: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?/manage-recommended-carton-qty'),
      viewRetailerList: path(ROOTS.version3, '/closed-offers-buyer-manager-version-2/details/:offerID?/view-retailer-list'),
    },

    
    // PURCHASE ORDER V3 - BUYER
    /** purchaseOrderBuyer: path(ROOTS.version3, '/purchase-order-buyer'),
    purchaseOrderBuyerDetails: {
      view: path(ROOTS.version3, '/purchase-order-buyer/details/:purchaseOrderID?'),
      edit: path(ROOTS.version3, '/purchase-order-buyer/details/:purchaseOrderID?/edit')
    },
    addPurchaseOrderBuyer: path(ROOTS.version3, '/purchase-order-buyer/new'), */

    // PURCHASE ORDER V3 - VERSION 2 ROS - BUYER
    purchaseOrderBuyerV2: path(ROOTS.version3, '/purchase-order-buyer'),
    purchaseOrderBuyerV2Details: {
      view: path(ROOTS.version3, '/purchase-order-buyer/details/:purchaseOrderID?'),
      edit: path(ROOTS.version3, '/purchase-order-buyer/details/:purchaseOrderID?/edit'),
      viewPOBreakdown: path(ROOTS.version3, '/purchase-order-buyer/details/:offerID?/po-breakdown/:productID?')
    },


    // PRICING V3 
    pricing: path(ROOTS.version3, '/pricing'),
    addNewPricing: {
      option1Mode: path(ROOTS.version3, '/pricing/add/option1'),
      option2Mode: path(ROOTS.version3, '/pricing/add/option2'),
      option3Mode: path(ROOTS.version3, '/pricing/add/option3')
    },
    viewPricing: {
      option1Mode: path(ROOTS.version3, '/pricing/details/option1/:pricingID?'),
      option2Mode: path(ROOTS.version3, '/pricing/details/option2/:pricingID?'),
      option3Mode: path(ROOTS.version3, '/pricing/details/option3/:pricingID?')
    },
    editPricing: {
      option1Mode: path(ROOTS.version3, '/pricing/details/option1/:pricingID?/edit'),
      option2Mode: path(ROOTS.version3, '/pricing/details/option2/:pricingID?/edit'),
      option3Mode: path(ROOTS.version3, '/pricing/details/option3/:pricingID?/edit')
    },

    // COSTING 
    costingView: path(ROOTS.version3, '/costing'),
    costingEdit: path(ROOTS.version3, '/costing/edit'),
    costingNew: path(ROOTS.version3, '/costing/new'),

    // SKU V3 - BUYER
    skuBuyer: path(ROOTS.version3, '/sku-buyer'),
    skuBuyerDetails: {
      view: path(ROOTS.version3, '/sku-buyer/details/:productID?')
    },

    //RETAILER
    retailer: path(ROOTS.version3, '/retailer'),
    addRetailer: path(ROOTS.version3, '/retailer/new'),
    retailerDetails: {
      view: path(ROOTS.version3, '/retailer/details/:applicationID?'),
      moduleSetting: path(ROOTS.version3, '/retailer/details/:applicationID/module-setting'),
      edit: path(ROOTS.version3, '/retailer/details/:retailerID?/edit')
    },
    addRetailerBulkUpload: path(ROOTS.version3, '/retailer/new/bulk-upload'),

    //SUPPLIER
    supplier: path(ROOTS.version3, '/supplier'),
    addSupplier: path(ROOTS.version3, '/supplier/new'),
    supplierDetails: {
      view: path(ROOTS.version3, '/supplier/details/:supplierID?')
    },

    //REPORTS
    reports: path(ROOTS.version3, '/reports'),

    // REPORTS - SALES
    reportsProductSalesSummary: path(ROOTS.version3, '/reports/view/product-sales-summary'),
    reportsProductSalesDetailed: path(ROOTS.version3, '/reports/view/product-sales-detailed'),
    reportsProductPriceList: path(ROOTS.version3, '/reports/view/product-price-list'),
    reportsSalesOrderSummary: path(ROOTS.version3, '/reports/view/sales-order-summary'),
    reportsCustomerOrderSummary: path(ROOTS.version3, '/reports/view/customer-order-summary'),

    // REPORTS - VENDOR
    reportsVendorList: path(ROOTS.version3, '/reports/view/vendor-list'),
    reportsPurchaseOrderSummary: path(ROOTS.version3, '/reports/view/purchase-order-summary'),
    reportsPurchaseOrderDetails: path(ROOTS.version3, '/reports/view/purchase-order-details'),
    reportsVendorProductList: path(ROOTS.version3, '/reports/view/vendor-product-list'),

    // REPORTS - PAYMENT
    reportsCustomerPaymentSummary: path(ROOTS.version3, '/reports/view/customer-payment-summary'),
    reportsCustomerPaymentByOrder: path(ROOTS.version3, '/reports/view/customer-payment-by-order'),
    reportsCustomerPaymentDetails: path(ROOTS.version3, '/reports/view/customer-payment-details'),


    // REPORTS - FINANCE
    reportsFinance: path(ROOTS.version3, '/reports-finance'),

    // TYPE
    inboundType: path(ROOTS.version3, '/inbound-type'),
    addInboundType: path(ROOTS.version3, '/inbound-type/new'),
    inboundTypeDetail: {
      view: path(ROOTS.version3, '/inbound-type/details/:inboundTypeID?'),
      edit: path(ROOTS.version3, '/inbound-type/details/:inboundTypeID?/edit')
    },

    // CARRIER
    inboundCarrier: path(ROOTS.version3, '/inbound-carrier'),
    addInboundCarrier: path(ROOTS.version3, '/inbound-carrier/new'),
    inboundCarrierDetail: {
      view: path(ROOTS.version3, '/inbound-carrier/details/:inboundCarrierID?'),
      edit: path(ROOTS.version3, '/inbound-carrier/details/:inboundCarrierID?/edit')
    },
    

    // PRODUCT CATEGORY
    productCategory: path(ROOTS.version3, '/product-management'),
    addProductCategory: path(ROOTS.version3, '/product-management/new'),
    productCategoryDetails: {
      view: path(ROOTS.version3, '/product-management/details/:productCategoryID?'),
      edit: path(ROOTS.version3, '/product-management/details/:productCategoryID?/edit')
    },

    // COURIER MANAGEMENT
    courierManagement: path(ROOTS.version3, '/courier-management'),
    addCourierManagement: path(ROOTS.version3, '/courier-management/new'),
    courierManagementDetails: {
      view: path(ROOTS.version3, '/courier-management/details/:courierID?'),
      edit: path(ROOTS.version3, '/courier-management/details/:courierID?/edit')
    },

    // OFFER TERMS
    offerTerms: path(ROOTS.version3, '/offer-terms'),
    addOfferTerms: path(ROOTS.version3, '/offer-terms/new'),
    offerTermsDetail: {
      view: path(ROOTS.version3, '/offer-terms/details/:offerTermID?'),
      edit: path(ROOTS.version3, '/offer-terms/details/:offerTermID?/edit')
    },

    // PAYMENT TERMS
    paymentTerms: path(ROOTS.version3, '/payment-terms'),
    addPaymentTerms: path(ROOTS.version3, '/payment-terms/new'),
    paymentTermsDetail: {
      view: path(ROOTS.version3, '/payment-terms/details/:paymentTermID?'),
      edit: path(ROOTS.version3, '/payment-terms/details/:paymentTermID?/edit')
    },

    // FILES IMPORT
    cooFiles: path(ROOTS.version3, '/coo-files'),
    loiFiles: path(ROOTS.version3, '/loi-files'),
    blFiles: path(ROOTS.version3, '/bl-files'),
    cocFiles: path(ROOTS.version3, '/coc-files'),
    plFiles: path(ROOTS.version3, '/pl-files'),
    poFiles: path(ROOTS.version3, '/po-files'),

    //EMAIL
    email: path(ROOTS.version3, '/email'),

    // TRACK PAYMENT SALES
    trackPayment: path(ROOTS.version3, '/track-payment'),
    trackPaymentView: path(ROOTS.version3, '/track-payment/details/:retailerID?'),

    // RMA
    rma: path(ROOTS.version3, '/rma'),
    addRMA: path(ROOTS.version3, '/rma/new'),
    rmaDetail: {
      view: path(ROOTS.version3, '/rma/details/:rmaID?')
    },
    
    // OFFER V3 - SALES MANAGER - V2
    offerSalesV2: path(ROOTS.version3, '/offer-sales-version-2'),
    offerSalesV2Details: {
      view: path(ROOTS.version3, '/offer-sales-version-2/details/:offerID?')
    },

    // RETAILER GROUP
    retailerGroup: path(ROOTS.version3, '/retailer-group'),
    addRetailerGroup: path(ROOTS.version3, '/retailer-group/new'),
    retailerGroupDetails: {
      view: path(ROOTS.version3, '/retailer-group/details/:retailerGroupID?'),
      edit: path(ROOTS.version3, '/retailer-group/details/:retailerGroupID?/edit')
    },

    // CONSIGNMENT SKU ORDER
    consignmentSKUOrder: path(ROOTS.version3, '/consignment-sku-order'),
    consignmentSKUOrderDetails: {
      new: path(ROOTS.version3, '/consignment-sku-order/details/:consignmentID?'),
      addProduct: path(ROOTS.version3, '/consignment-sku-order/details/:consignmentID?/add-product'),
      view: path(ROOTS.version3, '/consignment-sku-order/details/:consignmentID?/view'),
      viewRetailer: path(ROOTS.version3, '/consignment-sku-order/details/:consignmentID?/view/retailer/:productID?'),
      edit: path(ROOTS.version3, '/consignment-sku-order/details/:consignmentID?/edit')
    },

    // CONSIGNMENT SKU ORDER V2
    consignmentSKUOrderV2: path(ROOTS.version3, '/consignment-sku-order-v2'),
    consignmentSKUOrderV2Details: {
      new: path(ROOTS.version3, '/consignment-sku-order-v2/details/:consignmentID?'),
      addProduct: path(ROOTS.version3, '/consignment-sku-order-v2/details/:consignmentID?/add-product'),
      view: path(ROOTS.version3, '/consignment-sku-order-v2/details/:consignmentID?/view'),
      viewRetailer: path(ROOTS.version3, '/consignment-sku-order-v2/details/:consignmentID?/view/retailer/:productID?'),
      edit: path(ROOTS.version3, '/consignment-sku-order-v2/details/:consignmentID?/edit')
    },

    // CONSIGNMENT PRODUCT
    consignmentProduct: path(ROOTS.version3, '/consignment-product'),
    addConsignmentProduct: path(ROOTS.version3, '/consignment-product/new'),
    consignmentProductBulkUpload: {
      root: path(ROOTS.version3, '/consignment-product/bulk-upload'),
      addConsignmentProductBulkUpload: path(ROOTS.version3, '/consignment-product/bulk-upload/add-product'),
      editConsignmentProductBulkUpload: path(ROOTS.version3, '/consignment-product/bulk-upload/edit-product')
    },
    consignmentProductImagesBulkUpload: {
      root: path(ROOTS.version3, '/consignment-product/images-bulk-upload'),
      identicalImages: path(ROOTS.version3, '/consignment-product/images-bulk-upload/identical-images'),
      differentImages: path(ROOTS.version3, '/consignment-product/images-bulk-upload/different-images'),
    },

    consignmentProductCatalogue: {
      root: path(ROOTS.version3, '/consignment-product/generate-product-catalogue'),
      chooseProduct: path(ROOTS.version3, '/consignment-product/generate-product-catalogue/choose-product'),
    },

    consignmentProductDetails: {
      view: path(ROOTS.version3, '/consignment-product/details/:productID?'),
      edit: path(ROOTS.version3, '/consignment-product/details/:productID?/edit')
    },

    // CONSIGNMENT REPORT
    consignmentReport: path(ROOTS.version3, '/consignment-report'),
    consignmentReportNew: {
      sale: path(ROOTS.version3, '/consignment-report/add/sale'),
      addProduct: path(ROOTS.version3, '/consignment-report/add/sale/add-product'),
      auditStock: path(ROOTS.version3, '/consignment-report/add/audit-stock'),
      addProductAuditStock: path(ROOTS.version3, '/consignment-report/add/audit-stock/add-product')
    },
    consignmentReportDetails: {
      view: path(ROOTS.version3, '/consignment-report/details/:reportID?')
    },

    // CONSIGNMENT STOCK RETURNABLE
    consignmentStockReturnable: path(ROOTS.version3, '/consignment-stock-returnable'),
    addConsignmentStockReturnable: {
      new: path(ROOTS.version3, '/consignment-stock-returnable/new'),
      addProduct: path(ROOTS.version3, '/consignment-stock-returnable/new/add-product')
    },
    consignmentStockReturnableDetails: {
      view: path(ROOTS.version3, '/consignment-stock-returnable/details/:stockReturnableID?'),
      edit: path(ROOTS.version3, '/consignment-stock-returnable/details/:stockReturnableID?/edit')
    },

    // RMA V2
    rmaV2: path(ROOTS.version3, '/rma-v2'),
    rmaV2Details: {
      view: path(ROOTS.version3, '/rma-v2/details/:rmaID?')
    },

    // CONSIGNMENT RETAILER STOCK
    consignmentRetailerStock: path(ROOTS.version3, '/consignment-retailer-stock'),
    consignmentRetailerStockDetails: {
      view: path(ROOTS.version3, '/consignment-retailer-stock/details/:retailerID?'),
      skuHistory: path(ROOTS.version3, '/consignment-retailer-stock/details/:retailerID?/history/:productID?')
    },

    // COMPANY ACCOUNT
    companyAccount: path(ROOTS.version3, '/company-account'),

    //----------------ADMIN COMBINED VERSION MODULE------------------//
    // ORDER ADMIN //
    orderAdminSubMenuSales: {
      root: path(ROOTS.version3, '/order-admin/sales'),
      add: path(ROOTS.version3, '/order-admin/sales/new'),
      addBulkUpload: path(ROOTS.version3, '/order-admin/sales/new/bulk-upload'),
      view: path(ROOTS.version3, '/order-admin/sales/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/order-admin/sales/details/:invoiceID?/edit')
    },
    orderAdminSubMenuFinance: {
      root: path(ROOTS.version3, '/order-admin/finance'),
      view: path(ROOTS.version3, '/order-admin/finance/details/:invoiceID?')
    },
    orderAdminSubMenuLogistic: {
      root: path(ROOTS.version3, '/order-admin/logistic'),
      view: path(ROOTS.version3, '/order-admin/logistic/details/:invoiceID?')
    },

    // INVOICE ADMIN //
    invoiceAdminSubMenuSales: {
      root: path(ROOTS.version3, '/invoice-admin/sales'),
      view: path(ROOTS.version3, '/invoice-admin/sales/details/:invoiceID?')
    },
    invoiceAdminSubMenuFinance: {
      root: path(ROOTS.version3, '/invoice-admin/finance'),
      view: path(ROOTS.version3, '/invoice-admin/finance/details/:invoiceID?'),
      edit: path(ROOTS.version3, '/invoice-admin/finance/details/:invoiceID?/edit'),
      bulkUpload: path(ROOTS.version3, '/invoice-admin/finance/bulk-upload')
    },

    // INBOUND ADMIN //
    inboundAdminSubMenuSales: {
      root: path(ROOTS.version3, '/inbound-admin/sales'),
      view: path(ROOTS.version3, '/inbound-admin/sales/details/:inboundID?')
    },
    inboundAdminSubMenuFinance: {
      root: path(ROOTS.version3, '/inbound-admin/finance'),
      new: path(ROOTS.version3, '/inbound-admin/finance/details/:inboundID?/new'),
      view: path(ROOTS.version3, '/inbound-admin/finance/details/:inboundID?'),
      edit: path(ROOTS.version3, '/inbound-admin/finance/details/:inboundID?/edit'),
    },
    inboundAdminSubMenuLogistic: {
      root: path(ROOTS.version3, '/inbound-admin/logistic'),
      add: path(ROOTS.version3, '/inbound-admin/logistic/new'),
      details: {
        view: path(ROOTS.version3, '/inbound-admin/logistic/details/:inboundID?'),
        edit: path(ROOTS.version3, '/inbound-admin/logistic/details/:inboundID?/edit'),
        editGeneralInfo: path(ROOTS.version3, '/inbound-admin/logistic/details/:inboundID?/edit-general-info'),
        editFiles: path(ROOTS.version3, '/inbound-admin/logistic/details/:inboundID?/edit-files')
      }
    },

    // SINGLE SIDED RETAILERS
    singleSidedRetailers: {
      root: path(ROOTS.version3, '/single-sided-retailers'),
      new: path(ROOTS.version3, '/single-sided-retailers/new'),
      details: {
        view: path(ROOTS.version3, '/single-sided-retailers/details/:singleSidedRetailerID?'),
        edit: path(ROOTS.version3, '/single-sided-retailers/details/:singleSidedRetailerID?/edit'),
      }
    },

    // SUB DISTRIBUTOR //
    subdistributor: {
      root: path(ROOTS.version3, '/sub-distributor'),
      details: {
        view: path(ROOTS.version3, '/sub-distributor/details/:subdistributorID?'),
        moduleSetting: path(ROOTS.version3, '/sub-distributor/details/:subdistributorID?/module-setting'),
      }
    }

  }
}

export const PATH_FINANCE = {
  root: ROOTS.finance,
  general: {
    root: path(ROOTS.finance, '/dashboard'),
    dashboard: path(ROOTS.finance, '/dashboard'),

    //ORDER
    order: path(ROOTS.finance, '/order'),
    orderDetails: {
      view:  path(ROOTS.finance, '/order/details/:invoiceID?')
    },
    
    //PAYMENT
    payment: path(ROOTS.finance, '/payment'),
    paymentDetails: {
      view: path(ROOTS.finance, '/payment/details/:paymentID?')
    },

    //SHIPMENT
    shipment: path(ROOTS.finance, '/shipment'),
    addShipment: path(ROOTS.finance, '/shipment/new'),
    shipmentDetails:  {
      view: path(ROOTS.finance, '/shipment/details/:shipmentID?')
    },

    //REPORTS
    reports: path(ROOTS.finance, '/reports'),

    //EMAIL
    email: path(ROOTS.finance, '/email')

  }
}

export const PATH_LOGISTIC = {
  root: ROOTS.logistic,
  general: {
    root: path(ROOTS.logistic, '/dashboard'),
    dashboard: path(ROOTS.logistic, '/dashboard'),

    //ORDER
    invoice: path(ROOTS.logistic, '/order'),
    invoiceDetails: {
      view: path(ROOTS.logistic, '/order/details/:invoiceID?')
    },
    
    //RMA
    rma: path(ROOTS.logistic, '/rma'),
    addRma: path(ROOTS.logistic, '/rma/new'),
    rmaDetails: {
      edit: path(ROOTS.logistic, '/rma/details/:rmaID?')
    },

    //SHIPMENT
    shipment: path(ROOTS.logistic, '/shipment'),
    addShipment: path(ROOTS.logistic, '/shipment/new'),
    shipmentDetails:  {
      view: path(ROOTS.logistic, '/shipment/details/:shipmentID?')
    },

    //EMAIL
    email: path(ROOTS.logistic, '/email')
  }
}

export const PATH_ADMIN_KC = {
  root: ROOTS.adminKC,
  general: {
    root: path(ROOTS.adminKC, '/dashboard'),
    dashboard: path(ROOTS.adminKC, '/dashboard'),

    // NOTIFICATIONS
    notifications: path(ROOTS.adminKC, '/notifications'),
    notificationDetail: {
      view: path(ROOTS.adminKC, '/notifications/details/:notificationID?')
    },

    distributor: path(ROOTS.adminKC, '/distributor'),
    addDistributor: path(ROOTS.adminKC, '/distributor/new'),
    distributorDetails: {
      view: path(ROOTS.adminKC, '/distributor/details/:distributorID?/:applicationID?'),
      moduleSetting: path(ROOTS.adminKC, '/distributor/details/:distributorID?/:applicationID?/module-setting'),
      subdistributorSetting: path(ROOTS.adminKC, '/distributor/details/:distributorID?/:applicationID?/subdistributor-setting')
    },

    retailer: path(ROOTS.adminKC, '/retailer'),
    retailerDetails: {
      view: path(ROOTS.adminKC, '/retailer/details/:applicationID?')
    },

    branch: path(ROOTS.adminKC, '/branch'),
    branchDetails: {
      view: path(ROOTS.adminKC, '/branch/details/:branchID?')
    },

    user: path(ROOTS.adminKC, '/user'),
    addUser: path(ROOTS.adminKC, '/user/new'),
    userDetails: {
      view: path(ROOTS.adminKC, '/user/details/:userID?'),
      emailNotificationSetting: path(ROOTS.adminKC, '/user/details/:userID?/email-notification-setting')
    },

    role: path(ROOTS.adminKC, '/role'),
    addRole: path(ROOTS.adminKC, '/role/new'),
    roleDetails: {
      view: path(ROOTS.adminKC, '/role/details/:roleID?')
    },

    currency: path(ROOTS.adminKC, '/currency'),
    addCurrency: path(ROOTS.adminKC, '/currency/new'),
    currencyDetails: {
      view: path(ROOTS.adminKC, '/currency/details/:currencyID?'),
      edit: path(ROOTS.adminKC, '/currency/details/:currencyID?/edit')
    }
  }
}

export const PATH_ADMIN_REGISTER = {
  root: ROOTS.adminRegister,
  general: {
    root: path(ROOTS.adminRegister, '/distributor'),
    distributor: path(ROOTS.adminRegister, '/distributor'),
    distributorSubmitted: path(ROOTS.adminRegister, '/distributor/submitted'),
    distributorRejected: {
      detail: path(ROOTS.adminRegister, '/distributor/rejected'),
      new: path(ROOTS.adminRegister, '/distributor/rejected/new-form')
    }
  }
}

export const PATH_ACCOUNT_DISABLED = {
  root: ROOTS.accountDisabled,
  general: {
    root: path(ROOTS.accountDisabled, '/main')
  }
}

export const PATH_SUB_DISTRIBUTOR = {
  root: ROOTS.subdistributor,
  general: {
    root: path(ROOTS.subdistributor, '/main')
  },
  offers: {
    root: path(ROOTS.subdistributor, '/offers'),
    detail: path(ROOTS.subdistributor, '/offers/view/:offerID?'),
    offerSuccess: path(ROOTS.subdistributor, '/offers/view/apply/pre-order/success'),
    manageRating: path(ROOTS.subdistributor, '/offers/view/:offerID?/manage-rating'),
    manageRecommendedCartonQty: path(ROOTS.subdistributor, '/offers/view/:offerID?/manage-recommended-carton-qty')
  },
  preorder: {
    root: path(ROOTS.subdistributor, '/pre-order'),
    detail: {
      view: path(ROOTS.subdistributor, '/pre-order/view/:preOrderID?'),
      viewOrderDetail: path(ROOTS.subdistributor, '/pre-order/view/:preOrderID?/order-detail')
    },
  },
  closedOffers: {
    root: path(ROOTS.subdistributor, '/closed-offers'),
    view: path(ROOTS.subdistributor, '/closed-offers/view/:offerID?')
  },
  payment: {
    root: path(ROOTS.subdistributor, '/payment'),
    view: path(ROOTS.subdistributor, '/payment/view/:paymentID?')
  },

  order: path(ROOTS.subdistributor, '/order'),
  orderDetail: {
    view: path(ROOTS.subdistributor, '/order/:invoiceID?')
  },

  notification: {
    root: path(ROOTS.subdistributor, '/notifications'),
    view: path(ROOTS.subdistributor, '/notifications/details/:notificationID?')
  },

  creditNote: path(ROOTS.subdistributor, '/credit-note'),
  creditNoteDetail: {
    view: path(ROOTS.subdistributor, '/credit-note/view/:creditNoteID?')
  }
}